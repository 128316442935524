import React, { useRef, useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { Grid, InputLabel, Select, CircularProgress } from '@mui/material';

import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import Service from '../../../services/insurance-plan.service';
import CompanyService from '../../../services/companies.service';
import MuiButton from '../../../components/MuiButton';
import { TextField } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { ProductWrapper, SectionContent, FormControlCol3 } from './style';
import Cookies from 'js-cookie';

const InsurancePlanForm = (props) => {
	const [companyLists, setCompanyLists] = useState([]);
	const form = useRef();
	const { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = useState(false);

	//const companyId = props.location.state?.companyId

	const fetchCompanies = () => {
		Service.getCompaniesList()
			.then((response) => {
				// setcompanyNameLists(response?.data?.data);
				const res = response?.data?.data;
				if (res && companyLists.length == 0) {
					setCompanyLists(res);
				}
				Cookies.set('CSRF-TOKEN', response.headers['xsrf-token']);
				console.log(res);

				return () => {};

				console.log(res);
			})
			.catch((err) => {
				setCompanyLists([]);
				if (err?.response?.data) {
					enqueueSnackbar(err.response.data.message || 'Unable to fetch companies, try again', { variant: 'error' });
				} else {
					enqueueSnackbar(err.error_message, { variant: 'error' });
				}
			});
	};
	useEffect(() => {
		fetchCompanies();
	}, []);

	const onSubmit = (backendData) => {
		console.log(backendData);
		setLoading(true);
		const xsrfToken = Cookies.get('CSRF-TOKEN');
		Service.create({ ...backendData }, xsrfToken)
			.then((resp) => {
				setLoading(false);
				enqueueSnackbar('Changes made have been successfully saved', {
					variant: 'success',
				});
				props.history.push('/insurance-plan');
			})
			.catch((err) => {
				setLoading(false);
				if (err?.response?.data) {
					enqueueSnackbar(err.response.data.message, {
						variant: 'error',
					});
				} else {
					enqueueSnackbar(err.error_message, { variant: 'error' });
				}
			});
	};

	const validation = (values) => {
		const errors = {};
		if (!values.planName) {
			errors.planName = 'Plan name is required.';
		}
		// if (!values.subPlanName) {
		//   errors.subPlanName = 'Sub plan name is required.'
		// }
		if (!values.displayName) {
			errors.displayName = 'Display name is required.';
		}
		if (!values.companyId) {
			errors.companyId = 'Company is required.';
		}

		console.log(errors);
		return errors;
	};

	const formik = useFormik({
		initialValues: {
			planName: '',
			subPlanName: '',
			displayName: '',
			companyId: '',
		},
		validationSchema: Yup.object({
			planName: Yup.string().required('Plan name is Required'),
			//subPlanName: Yup.string().required('Sub Plan name is Required'),
			displayName: Yup.string().required('Display name is Required'),
			companyId: Yup.string().required('Company is Required'),
		}),
		validate: (values) => validation(values),
		onSubmit: (values) => {
			console.log(values);
			const updatedValues = { ...values };
			onSubmit(updatedValues);
		},
	});

	return (
		<Grid fullWidth>
			<ProductWrapper>
				<form ref={form} onSubmit={formik.handleSubmit}>
					<SectionContent>
						<FormControlCol3>
							<InputLabel>Company Name</InputLabel>
							<TextField
								error={Boolean(formik.touched.companyId && formik.errors.companyId)}
								fullWidth
								helperText={formik.touched.companyId && formik.errors.companyId}
								label="Company Name"
								margin="normal"
								name="companyId"
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								select
								value={formik.values.companyId}
								variant="outlined"
							>
								{(companyLists || companyLists.length > 0) &&
									companyLists.map((option) => (
										<MenuItem key={option.id} value={option.id}>
											{option.companyName}
										</MenuItem>
									))}
							</TextField>
						</FormControlCol3>
						<FormControlCol3>
							<InputLabel>Plan Name</InputLabel>
							<TextField
								error={Boolean(formik.touched.planName && formik.errors.planName)}
								fullWidth
								helperText={formik.touched.planName && formik.errors.planName}
								label="Plan Name"
								margin="normal"
								name="planName"
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								select
								value={formik.values.planName}
								variant="outlined"
							>
								{[
									{ value: 'Comprehensive', label: 'Comprehensive' },
									{ value: 'TLO', label: 'TLO' },
								].map((option) => (
									<MenuItem key={option.value} value={option.value}>
										{option.label}
									</MenuItem>
								))}
							</TextField>
						</FormControlCol3>
						{/* <FormControlCol3>
              <InputLabel>Sub Plan Name</InputLabel>
              <TextField
                error={Boolean(formik.touched.subPlanName && formik.errors.subPlanName)}
                fullWidth
                helperText={formik.touched.subPlanName && formik.errors.subPlanName}
                label='Sub Plan Name'
                margin='normal'
                name='subPlanName'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type='text'
                value={formik.values.subPlanName}
                variant='outlined'
              />
            </FormControlCol3> */}
						<FormControlCol3>
							<InputLabel>Display Name</InputLabel>
							<TextField
								error={Boolean(formik.touched.displayName && formik.errors.displayName)}
								fullWidth
								helperText={formik.touched.displayName && formik.errors.displayName}
								label="Display Name"
								margin="normal"
								name="displayName"
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								type="text"
								value={formik.values.displayName}
								variant="outlined"
							/>
						</FormControlCol3>
					</SectionContent>
				</form>
			</ProductWrapper>

			<Grid container direction="row" justifyContent="start" alignItems="center" p={3}>
				<MuiButton variant="contained" type="submit" onClick={formik.handleSubmit} disable={loading} color="primary">
					{loading && <CircularProgress color="secondary" size={20} />}Submit
				</MuiButton>
			</Grid>
		</Grid>
	);
};

export default InsurancePlanForm;
