import React, { useState, useEffect } from 'react';
import {
	IconButton,
	TableCell,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Paper,
} from '@mui/material';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import { customDate } from '../../../util';
import Loader from '../../../components/Loader';
import authService from '../../../services/auth.service';

const ClaimTable = ({ data, handleView, setClaimDataPage, setClaimDataLimit, claimDataPage, claimDataLimit }) => {
	const [gridLoader, setGridLoader] = useState(false);
	const [canEdit, setCanEdit] = useState(true);

	useEffect(() => {
		setGridLoader(false);
		const user = authService.getCurrentUser();
		if (user?.permissions && user?.permissions === 'read-only') {
			setCanEdit(false);
		}
	}, [data?.listClaims]);

	const handleChangePage = (event, newPage) => {
		setGridLoader(true);
		setClaimDataPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setGridLoader(true);
		setClaimDataPage(0);
		setClaimDataLimit(+event.target.value);
	};

	const claimStatusFormat = (status) => {
		return status;
	};

	return (
		<Paper sx={{ position: 'relative', overflowX: 'clip' }}>
			{data?.lengthDoc === 0 && <Loader message={data} />}
			{data?.lengthDoc && (
				<>
					{(!data?.listClaims || gridLoader) && <Loader showLoader />}
					<TableContainer>
						<Table stickyHeader aria-label="sticky table">
							<TableHead>
								<TableRow>
									<TableCell align="left">Insured Name</TableCell>
									<TableCell align="left">Policy No</TableCell>
									<TableCell align="left">Loss Date</TableCell>
									<TableCell align="left">Location</TableCell>
									<TableCell align="left">Vehicle Registration</TableCell>
									<TableCell align="left">Loss Cause</TableCell>
									<TableCell align="left">Driven By</TableCell>
									<TableCell align="left">Reported By</TableCell>
									<TableCell align="left">Status</TableCell>
									{canEdit && <TableCell align="left">Action</TableCell>}
								</TableRow>
							</TableHead>
							<TableBody>
								{data?.listClaims?.map((row) => {
									return (
										<TableRow hover role="checkbox" key={row?._id}>
											<TableCell align="left">{row?.insuredName}</TableCell>
											<TableCell align="left">{row?.policyNo}</TableCell>
											<TableCell align="left">{customDate(row?.lossDate)}</TableCell>
											<TableCell align="left">{row?.location}</TableCell>
											<TableCell align="left">{row?.vehicleRegistration}</TableCell>
											<TableCell align="left">{row?.lossCause}</TableCell>
											<TableCell align="left">{row?.drivenBy}</TableCell>
											<TableCell align="left">{row?.reportedBy}</TableCell>
											<TableCell align="left">{claimStatusFormat(row?.progress[0]?.status)}</TableCell>
											{canEdit && (
												<TableCell align="left">
													<IconButton size="small" onClick={(event) => handleView(event, row)}>
														<ArticleOutlinedIcon fontSize="small" />
													</IconButton>
												</TableCell>
											)}
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[10, 25, 50, 100]}
						component="div"
						count={data.lengthDoc}
						rowsPerPage={claimDataLimit}
						page={claimDataPage}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</>
			)}
		</Paper>
	);
};

export default ClaimTable;
