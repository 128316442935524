import React from 'react'
import { Dialog as Modal } from '@mui/material'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

export const Confirmation = ({
  title,
  onSubmit,
  submitMsg = 'Yes',
  cancelMsg = 'Cancel',
  onClose,
  onCancel,
  ...rest
}) => {
  return (
    <Modal {...rest}>
      <DialogTitle id='alert-dialog-title'>
        Confirmation
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {title}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ padding: '0 24px 20px' }}>
        <Button onClick={onCancel || onClose}>
          {cancelMsg}
        </Button>
        <Button
          color='error'
          variant='contained'
          onClick={onSubmit}
          autoFocus
        >
          {submitMsg}
        </Button>
      </DialogActions>
    </Modal>
  )
}
