import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Grid, Typography } from '@mui/material';
import PostLogin from '../../layout/post-login';
import PolicyTable from './components/policyTable';
import PolicyView from './components/policyView';
import PolicyService from '../../services/policy.service.js';
import Loader from '../../components/Loader';
import SearchList from '../../components/SearchList';

const PolicyComponent = (props) => {
	const { enqueueSnackbar } = useSnackbar();

	const [openPolicyView, setOpenPolicyView] = useState(false);
	const [policyData, setPolicyData] = useState();
	const [selectedRow, setSelectedRow] = useState({});
	const [selectedRowId, setSelectedRowId] = useState(null);

	const [policyDataPage, setPolicyDataPage] = useState(0);
	const [policyDataLimit, setPolicyDataLimit] = useState(10);

	const loadPolicyData = () => {
		PolicyService.getPolicyList(policyDataPage, policyDataLimit)
			.then((response) => {
				console.log(response?.data?.data);
				setPolicyData(response?.data?.data);
			})
			.catch((err) => {
				if (err?.response?.data) {
					enqueueSnackbar(err.response.data.error_message, { variant: 'error' });
				} else {
					enqueueSnackbar(err.error_message, { variant: 'error' });
				}
			});
	};

	const handleView = (event, row) => {
		event.stopPropagation();
		setSelectedRowId(row?.id);
		PolicyService.policyDetail(row?.id)
			.then((response) => {
				setSelectedRow(response?.data?.data);
				setOpenPolicyView(true);
				setSelectedRowId(null);
			})
			.catch((err) => {
				if (err?.response?.data) {
					enqueueSnackbar(err.response.data.error_message, { variant: 'error' });
				} else {
					enqueueSnackbar(err.error_message, { variant: 'error' });
				}
			});
	};

	const handleClose = () => {
		setOpenPolicyView(false);
		setSelectedRow({});
	};

	useEffect(() => {
		loadPolicyData();
	}, [policyDataPage, policyDataLimit]);

	const getSearchList = (backendData) => {
		PolicyService.getPolicyList(0, 10, backendData)
			.then((response) => {
				if (response?.data?.data) {
					setPolicyData({ nextpage: { page: 1, limit: 10 }, lengthDoc: 1, ...response?.data?.data });
				} else {
					enqueueSnackbar('No Data found', { variant: 'error' });
				}
			})
			.catch((err) => {
				if (err?.response?.data) {
					enqueueSnackbar(err.response.data.error_message, { variant: 'error' });
				} else {
					enqueueSnackbar(err.error_message, { variant: 'error' });
				}
			});
	};

	const uploadPolicySoftCopy = (id, data) => {
		PolicyService.uploadSoftCopy(id, data)
			.then((res) => {
				handleClose();
				enqueueSnackbar(res?.data.message, { variant: 'success' });
			})
			.catch((err) => {
				enqueueSnackbar(err.response.data.message, { variant: 'error' });
			});
	};

	const handleDecline = (quotationId, userId, reason) => {
		if (!reason || reason === '') {
			reason = ' ';
		}
		PolicyService.declinePolicy(quotationId, { declineReason: reason, isActive: false })
			.then((response) => {
				enqueueSnackbar('Policy status has been changed.', { variant: 'success' });
				setPolicyData(null);
				loadPolicyData();
			})
			.catch((err) => {
				console.log(err);
				if (err?.response?.data) {
					enqueueSnackbar('Decline process failed.', { variant: 'error' });
				} else {
					enqueueSnackbar('Decline process failed.', { variant: 'error' });
				}
			});
	};

	return (
		<PostLogin>
			<Grid sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', pb: 2 }} container>
				<Typography variant="h5" component="div">
					Policy Page{' '}
				</Typography>
				<SearchList placeholder="Search" onSubmit={getSearchList} onClear={loadPolicyData} searchInfo="" />
			</Grid>
			{!policyData?.currentPageRecords && (
				<Grid sx={{ width: '100%', display: 'flex', justifyContent: 'space-around' }} container>
					<Loader showLoader outlinedLoader />
				</Grid>
			)}
			<PolicyTable
				data={policyData}
				handleView={handleView}
				selectedRowId={selectedRowId}
				setPolicyDataPage={setPolicyDataPage}
				setPolicyDataLimit={setPolicyDataLimit}
				policyDataPage={policyDataPage}
				policyDataLimit={policyDataLimit}
				handleDecline={handleDecline}
			/>

			{openPolicyView && (
				<PolicyView
					data={selectedRow}
					onClose={handleClose}
					refetch={loadPolicyData}
					uploadSoftCopy={uploadPolicySoftCopy}
					open={openPolicyView}
					{...props}
				/>
			)}
		</PostLogin>
	);
};

export default PolicyComponent;
