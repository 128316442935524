import React, { useState, useEffect } from 'react';
import {
	IconButton,
	TableCell,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Paper,
	Tooltip,
} from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Loader from '../../../components/Loader';
import moment from 'moment';
import authService from '../../../services/auth.service';

const AgentTable = ({
	data,
	handleEdit,
	handleDelete,
	setAgentDataPage,
	setAgentDataLimit,
	agentDataPage,
	agentDataLimit,
}) => {
	const [gridLoader, setGridLoader] = useState(false);
	const [canEdit, setCanEdit] = useState(true);
	useEffect(() => {
		setGridLoader(false);
		const user = authService.getCurrentUser();
		if (user?.permissions && user?.permissions === 'read-only') {
			setCanEdit(false);
		}
	}, [data?.agents]);

	const handleChangePage = (event, newPage) => {
		setGridLoader(true);
		setAgentDataPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setGridLoader(true);
		setAgentDataPage(0);
		setAgentDataLimit(+event.target.value);
	};

	return (
		<Paper sx={{ position: 'relative', overflowX: 'clip' }}>
			{data?.lengthDoc === 0 && <Loader message={data} />}
			{data?.lengthDoc && (
				<>
					{(!data?.agents || gridLoader) && <Loader showLoader />}
					<TableContainer>
						<Table stickyHeader aria-label="sticky table">
							<TableHead>
								<TableRow>
									<TableCell align="left">name</TableCell>
									<TableCell align="left">email</TableCell>
									<TableCell align="left">phone</TableCell>
									<TableCell align="left">referral code</TableCell>
									<TableCell align="left">referral by</TableCell>
									<TableCell align="left">Scheme</TableCell>
									<TableCell align="left">sign up date</TableCell>
									{canEdit && <TableCell align="center">Action</TableCell>}
								</TableRow>
							</TableHead>
							<TableBody>
								{data?.agents?.map((row) => {
									return (
										<TableRow hover role="checkbox" key={row?.id}>
											<TableCell align="left">{row?.name}</TableCell>
											<TableCell align="left">{row?.email}</TableCell>
											<TableCell align="left">{row?.phone}</TableCell>
											<TableCell align="left">{row?.referral_code}</TableCell>
											<TableCell align="left">{row?.referred_by_name || ''}</TableCell>
											<TableCell align="left">{row?.multilineScheme}</TableCell>
											<TableCell align="left">{moment(row?.createdAt).format('yyyy-MM-DD')}</TableCell>
											{canEdit && (
												<TableCell align="center">
													<Tooltip title="Edit">
														<IconButton size="small" onClick={(event) => handleEdit(event, row)}>
															<EditOutlinedIcon fontSize="small" />
														</IconButton>
													</Tooltip>
													<Tooltip title="Delete">
														<IconButton size="small" onClick={(event) => handleDelete(event, row)}>
															<DeleteOutlinedIcon fontSize="small" />
														</IconButton>
													</Tooltip>
												</TableCell>
											)}
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[10, 25, 50, 100]}
						component="div"
						count={data.lengthDoc}
						rowsPerPage={agentDataLimit}
						page={agentDataPage}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</>
			)}
		</Paper>
	);
};

export default AgentTable;
