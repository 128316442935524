import React, { useEffect } from 'react'
import {
  Grid,
  Typography
} from '@mui/material'
import PostLogin from '../../../layout/post-login'
import BannerForm from './add'

const BannerAdd = (props) => {
  const detail = props.location.state?.detail
  console.log(detail)
  return (
    <PostLogin>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <Grid item>
          <Typography variant='h5' gutterBottom component='h5'>Banner Page {detail ? 'Edit' : 'Add'}</Typography>
        </Grid>
      </Grid>

      <BannerForm
        mode={detail ? 'edit' : 'add'}
        data={detail || {}}
        {...props}
      />
    </PostLogin>
  )
}

export default BannerAdd
