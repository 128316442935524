import Alert from '@mui/material/Alert';
import React from 'react';

const Error = ({ messages }) => {
	return (
		<Alert severity="error">
			{messages.map((msg, index) => (
				<li key={index}>{msg}</li>
			))}
		</Alert>
	);
};

export default Error;
