import axios from 'axios';
import authHeader from './auth-header';

const API_URL = `${process.env.REACT_APP_API_URL}of-agent/`;
const AGENTS_LIST_URL = `${process.env.REACT_APP_URL}/v1/agent/agents-to-export`;
const SCHEME_LIST_URL = `${process.env.REACT_APP_URL}/v1/insurer-scheme/scheme/list`;

const getAgentList = (page = 1, limit = 5, createdAt = null) => {
	const url = `${API_URL}AgentList?page=${page}&limit=${limit}&createdAt=${createdAt || ''}`;
	return axios.get(url, { headers: authHeader() });
};

const createAgent = (backendData) => {
	const url = `${API_URL}`;
	return axios.post(url, { ...backendData }, { headers: authHeader() });
};

const updateAgent = (id, data) => {
	const url = `${API_URL}${id}`;
	return axios.post(url, data, { headers: authHeader() });
};

const updateAgentAdmin = (id, data) => {
	const url = `${API_URL}admin/${id}`;
	return axios.patch(url, data, { headers: authHeader() });
};

const deleteAgent = (id) => {
	const url = `${API_URL}${id}`;
	return axios.delete(url, { headers: authHeader() });
};

const agentProfile = (id) => {
	const url = `${API_URL}${id}`;
	return axios.get(url, { headers: authHeader() });
};

const agentsList = () => {
	return axios.post(AGENTS_LIST_URL, { headers: authHeader() });
};

const schemesList = () => {
	return axios.get(SCHEME_LIST_URL, { headers: authHeader() });
};

export default {
	getAgentList,
	deleteAgent,
	updateAgent,
	createAgent,
	updateAgentAdmin,
	agentProfile,
	agentsList,
	schemesList,
};
