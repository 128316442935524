import React from 'react';
import { Chip } from '@mui/material';
import moment from 'moment';

export const toLocaleString = (num) => {
	return !isNaN(num) ? Number(num)?.toLocaleString('en-ID') : 0;
};

export const customDate = (dat) => {
	return moment(new Date(dat)).format('DD/MM/YYYY');
};

export const paymentStatusFormat = (status) => {
	if (status === 'COMPLETE') {
		return <Chip label={status} size="small" color="success" />;
	} else if (status === 'WAITING_PAYMENT') {
		return <Chip label="WAITING PAYMENT" size="small" color="warning" />;
	} else if (status === 'PAYMENT_DETECTED') {
		return <Chip label="PAYMENT DETECTED" size="small" color="primary" />;
	} else if (status === 'EXPIRED') {
		return <Chip label={status} size="small" color="error" />;
	} else if (status === 'STATIC_TRX_EXPIRED') {
		return <Chip label="STATIC TRX EXPIRED" size="small" color="error" />;
	} else if (status === 'WAITING_APPROVAL') {
		return <Chip label="WAITING APPROVAL" size="small" color="warning" />;
	} else if (status === 'APPROVED') {
		return <Chip label="APPROVED" size="small" color="success" />;
	} else if (status === 'DECLINED') {
		return <Chip label="DECLINED" size="small" color="error" />;
	} else if (status === 'PAID') {
		return <Chip label="PAID" size="small" color="info" />;
	} else {
		return status;
	}
};

export const policyStatusFormat = (status) => {
	if (status === 'SUCCESS') {
		return <Chip label={status} size="small" color="success" />;
	} else {
		return status;
	}
};

export const policyActiveStatusFormat = (status, reason) => {
	if (status) {
		return <Chip label={'Active'} size="small" color="success" />;
	} else if (!status && reason) {
		return <Chip label={'Aborted'} size="small" color="error" />;
	} else {
		return <Chip label={'Expired'} size="small" color="warning" />;
	}
};
