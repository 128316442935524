import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { Grid, Typography, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Confirmation } from '../../../components/Confirmation';
import PostLogin from '../../../layout/post-login';
import List from './list';
import Service from '../../../services/insurance-plan.service';
import authService from '../../../services/auth.service';
import Cookies from 'js-cookie';

const InsuranceList = (props) => {
	const { enqueueSnackbar } = useSnackbar();
	const [mode, setMode] = useState('');
	const [openConfirmation, setConfirmation] = useState(false);
	const [companyData, setcompanyData] = useState();
	const [selectedRow, setSelectedRow] = useState({});
	const [companyDataPage, setcompanyDataPage] = useState(0);
	const [companyDataLimit, setcompanyDataLimit] = useState(10);
	const [canEdit, setCanEdit] = useState(true);

	const loadCompanyData = () => {
		Service.getList(companyDataPage, companyDataLimit)
			.then((response) => {
				Cookies.set('CSRF-TOKEN', response.headers['xsrf-token']);
				if (response?.data?.data) setcompanyData(response?.data?.data);
				else setcompanyData({ data: { data: [] } });
			})
			.catch((err) => {
				setcompanyData({ data: [] });
				if (err?.response?.data) {
					enqueueSnackbar(err.response.data.error_message || 'Unable to fetch list', { variant: 'error' });
				} else {
					enqueueSnackbar(err.error_message, { variant: 'error' });
				}
			});
	};

	const handleDelete = (event, row) => {
		event.stopPropagation();
		setSelectedRow(row);
		setMode('Delete');
		setConfirmation(true);
	};

	const handleClose = () => {
		setConfirmation(false);
		setSelectedRow({});
		// setMode('Add')
	};

	const handleToggle = (event, row) => {
		console.log(row);
		const xsrfToken = Cookies.get('CSRF-TOKEN');
		Service.statusToggle(row?._id, !row?.isActive, xsrfToken)
			.then((response) => {
				loadCompanyData();
				enqueueSnackbar(`${row?.planName}'s plan is successfully updated.`, { variant: 'success' });
			})
			.catch((err) => {
				console.log(err);
				enqueueSnackbar(
					err?.response?.data?.message || `Some error occured while updating ${row?.planName || 'this company'}.`,
					{ variant: 'error' },
					err
				);
			});
	};

	const confirmationDelete = () => {
		// setLoading(true)

		const xsrfToken = Cookies.get('CSRF-TOKEN');
		Service.deletePlan(selectedRow?._id, xsrfToken)
			.then((response) => {
				setConfirmation(false);
				// setLoading(false)
				loadCompanyData();
				enqueueSnackbar(`${selectedRow?.planName}'s plan is Deleted.`, { variant: 'success' });
			})
			.catch((err) => {
				setConfirmation(false);
				// setLoading(false)
				enqueueSnackbar(
					`Some error occured while deleting ${selectedRow?.planName || 'this company'}.`,
					{ variant: 'error' },
					err
				);
			});
	};

	useEffect(() => {
		loadCompanyData();
		const user = authService.getCurrentUser();
		if (user?.permissions && user?.permissions === 'read-only') {
			setCanEdit(false);
		}
	}, [companyDataPage, companyDataLimit]);

	return (
		<PostLogin>
			<Grid container direction="row" justifyContent="space-between" alignItems="center">
				<Grid item>
					<Typography variant="h5" gutterBottom component="h5">
						Insurance Plans
					</Typography>
				</Grid>
				{canEdit && (
					<Grid item>
						<Link to="/insurance-plan/add">
							<Button variant="contained" size="small" startIcon={<AddIcon />}>
								Add Insurance Plan
							</Button>
						</Link>
					</Grid>
				)}
			</Grid>
			<List
				data={companyData}
				handleDelete={handleDelete.bind(null)}
				setcompanyDataPage={setcompanyDataPage.bind(null)}
				setcompanyDataLimit={setcompanyDataLimit.bind(null)}
				companyDataPage={companyDataPage}
				companyDataLimit={companyDataLimit}
				handleToggle={handleToggle.bind(null)}
			/>

			{/* {
        openProductForm &&
          <ProductForm
            mode={mode}
            data={selectedRow}
            onClose={handleClose}
            refetch={loadcompanyData}
            open={openProductForm}
            {...props}
          />
      } */}

			{openConfirmation && (
				<Confirmation
					open={openConfirmation}
					title={`Are you sure you want to delete the plan ${selectedRow?.planName}?`}
					submitMsg={mode}
					onSubmit={() => {
						confirmationDelete();
					}}
					onClose={handleClose.bind(null)}
				/>
			)}
		</PostLogin>
	);
};

export default InsuranceList;
