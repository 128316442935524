import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Confirmation } from '../../components/Confirmation';
import { Grid, Button, Typography, Stack, FormControl, TextField, Box } from '@mui/material';
import PostLogin from '../../layout/post-login';
import AddIcon from '@mui/icons-material/Add';
import AgentTable from './components/agentTable';
import AgentForm from './components/agentForm';
import AgentService from '../../services/agent.service';
import Loader from '../../components/Loader';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import ExportAgent from './components/exportAgents';

const AgentComponent = (props) => {
	const { enqueueSnackbar } = useSnackbar();

	// const [loading, setLoading] = useState(false)
	const [openAgentForm, setOpenAgentForm] = useState(false);
	const [agentData, setAgentData] = useState();
	const [selectedRow, setSelectedRow] = useState({});
	const [mode, setMode] = useState('Add');
	const [openConfirmation, setConfirmation] = useState(false);

	const [agentDataPage, setAgentDataPage] = useState(0);
	const [agentDataLimit, setAgentDataLimit] = useState(10);
	const [filterDate, setFilterDate] = useState(null);
	const [triggerLoad, setTriggerLoad] = useState(false);
	const [openExportView, setOpenExportView] = useState(false);

	const loadAgentData = () => {
		// setLoading(true)
		AgentService.getAgentList(agentDataPage, agentDataLimit, filterDate)
			.then((response) => {
				// setLoading(false)
				setAgentData(response?.data?.data);
			})
			.catch((err) => {
				// setLoading(false)
				if (err?.response?.data) {
					enqueueSnackbar(err.response.data.message, { variant: 'error' });
				} else {
					enqueueSnackbar(err.message, { variant: 'error' });
				}
			});
	};

	const handleAdd = () => {
		setOpenAgentForm(true);
		setSelectedRow({});
		setMode('Add');
	};

	const handleEdit = (event, row) => {
		event.stopPropagation();
		setOpenAgentForm(true);
		setSelectedRow(row);
		setMode('Edit');
	};

	const handleDelete = (event, row) => {
		event.stopPropagation();
		setSelectedRow(row);
		setMode('Delete');
		setConfirmation(true);
	};

	const handleClose = () => {
		setOpenAgentForm(false);
		setConfirmation(false);
		setSelectedRow({});
		setMode('Add');
	};

	const handleFilter = (e) => {
		setAgentDataPage(0);
		setAgentDataLimit(10);
		setAgentData(null);
		setTriggerLoad(!triggerLoad);
	};

	const handleResetFilter = (e) => {
		setAgentDataPage(0);
		setAgentDataLimit(10);
		setAgentData(null);
		setFilterDate(null);
		setTriggerLoad(!triggerLoad);
	};

	const handleExportView = (event) => {
		event.stopPropagation();
		setOpenExportView(true);
		setSelectedRow({});
	};

	const handleCloseExport = () => {
		setOpenExportView(false);
		setSelectedRow({});
	};

	const handleExportAll = (event) => {
		event.stopPropagation();
		let url = `${process.env.REACT_APP_AGENT_API_URL}/agent/export/agent-list`;
		window.open(url);
	};

	const confirmationDeleteAgentSubmit = () => {
		// setLoading(true)

		AgentService.deleteAgent(selectedRow?.id)
			.then((response) => {
				setConfirmation(false);
				// setLoading(false)
				loadAgentData();
				enqueueSnackbar(`${selectedRow?.name}'s agent is Deleted.`, { variant: 'success' });
			})
			.catch((err) => {
				setConfirmation(false);
				// setLoading(false)
				enqueueSnackbar(
					`Some error occured while deleting ${selectedRow?.name || 'this agent'}.`,
					{ variant: 'error' },
					err
				);
			});
	};

	useEffect(() => {
		loadAgentData();
	}, [agentDataPage, agentDataLimit, triggerLoad]);

	return (
		<PostLogin>
			<Grid container alignItems="center">
				<Grid item xs>
					<Box component="span" m={1}>
						<Typography variant="h5" gutterBottom component="div">
							Agents Page
						</Typography>
					</Box>
				</Grid>
				<Grid item m={1}>
					<Button variant="outlined" sx={{ height: 40 }} onClick={(event) => handleExportView(event)}>
						Export
					</Button>
				</Grid>
				<Grid item m={1}>
					<Button variant="outlined" sx={{ height: 40 }} onClick={(event) => handleExportAll(event)}>
						Export All
					</Button>
				</Grid>
			</Grid>
			<br />
			<Grid>
				<Stack spacing={2} direction="row">
					<FormControl>
						<LocalizationProvider dateAdapter={AdapterMoment}>
							<DesktopDatePicker
								label="Signup Date"
								value={filterDate}
								onChange={(newValue) => {
									if (newValue) {
										setFilterDate(newValue.format('yyyy-MM-DD'));
									} else {
										setFilterDate(null);
									}
								}}
								inputFormat="yyyy-MM-DD"
								renderInput={(params) => <TextField {...params} />}
							/>
						</LocalizationProvider>
					</FormControl>
					<FormControl>
						<Button color="success" variant="contained" onClick={handleFilter}>
							Filter
						</Button>
					</FormControl>
					<FormControl>
						<Button variant="contained" onClick={handleResetFilter}>
							Reset Filter
						</Button>
					</FormControl>
				</Stack>
			</Grid>
			<br />

			{!agentData && <Loader showLoader outlinedLoader />}
			<AgentTable
				// loading={loading}
				data={agentData}
				handleEdit={handleEdit}
				handleDelete={handleDelete}
				setAgentDataPage={setAgentDataPage}
				setAgentDataLimit={setAgentDataLimit}
				agentDataPage={agentDataPage}
				agentDataLimit={agentDataLimit}
			/>

			{openAgentForm && (
				<AgentForm
					mode={mode}
					data={selectedRow}
					onClose={handleClose}
					refetch={loadAgentData}
					open={openAgentForm}
					{...props}
				/>
			)}

			{openConfirmation && (
				<Confirmation
					open={openConfirmation}
					title={`Are you sure you want to delete the agent ${selectedRow?.name}?`}
					submitMsg={mode}
					onSubmit={() => {
						confirmationDeleteAgentSubmit();
					}}
					onClose={handleClose}
				/>
			)}

			{openExportView && (
				<ExportAgent onClose={handleCloseExport} refetch={loadAgentData} open={openExportView} {...props} />
			)}
		</PostLogin>
	);
};

export default AgentComponent;
