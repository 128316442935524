import React, { useState, useEffect } from 'react';
import {
	IconButton,
	TableCell,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Paper,
	Tooltip,
	Button,
	Chip,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CircularProgress } from '@mui/material';

import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import CheckIcon from '@mui/icons-material/Check';
import BlockIcon from '@mui/icons-material/Block';
import { toLocaleString, paymentStatusFormat } from '../../../util';
import Loader from '../../../components/Loader';
import authService from '../../../services/auth.service';

const QuotationTable = ({
	data,
	handleApprove,
	handleDecline,
	handleView,
	openingView,
	setQuotationDataPage,
	setQuotationDataLimit,
	quotationDataPage,
	quotationDataLimit,
	allQuotations,
}) => {
	const [gridLoader, setGridLoader] = useState(false);
	const [openDeclineDialog, setOpenDeclineDialog] = useState(false);
	const [selectedRow, setSelectedRow] = useState({
		quotationId: null,
		userId: null,
		declineReason: '',
	});
	const [openApprovalDialog, setOpenApprovalDialog] = useState(false);
	const [selectedApproveRow, setSelectedApproveRow] = useState({ quotationId: null, quotationNumber: null });
	const [canEdit, setCanEdit] = useState(true);

	useEffect(() => {
		setGridLoader(false);
		const user = authService.getCurrentUser();
		if (user?.permissions && user?.permissions === 'read-only') {
			setCanEdit(false);
		}
	}, [data]);

	const handleChangePage = (event, newPage) => {
		setGridLoader(true);
		console.log(newPage);
		setQuotationDataPage(newPage + 1);
	};

	const handleChangeRowsPerPage = (event) => {
		setGridLoader(true);
		setQuotationDataPage(1);
		setQuotationDataLimit(+event.target.value);
	};

	const handleValueChanged = (event) => {
		setSelectedRow((current) => {
			return {
				...current,
				[event.target.name]: event.target.value,
			};
		});
	};

	const handleDeclineButton = (quotationId) => {
		setSelectedRow((current) => {
			return {
				...current,
				quotationId,
			};
		});
		setOpenDeclineDialog(true);
	};

	const handleApproveButtonClick = (selectRow) => {
		setSelectedApproveRow((current) => {
			return {
				...current,
				quotationId: selectRow.id || selectRow._id,
				quotationNumber: selectRow.quotationNumber,
			};
		});
		setOpenApprovalDialog(true);
	};
	const capitalizeFirstLetter = (string) => {
		return string.charAt(0).toUpperCase() + string.slice(1);
	};

	const quotationStatusClass = (value) => {
		if (value == 'APPROVED') {
			return '#4db6ac';
		} else if (value == 'REJECTED') {
			return '#ef5350';
		} else if (value == 'COMPLETE') {
			return '#3949ab';
		} else if (value == 'WAITING_APPROVAL') {
			return '#ffb74d';
		}
		return '#DFCCFB';
	};
	return (
		<>
			<Paper sx={{ position: 'relative', overflowX: 'clip' }}>
				{data?.length === 0 && <Loader message={data} />}
				{data?.length && (
					<>
						{(!data || gridLoader) && <Loader showLoader />}
						<TableContainer>
							<Table stickyHeader aria-label="sticky table">
								<TableHead>
									<TableRow>
										<TableCell align="left">Quotation ID</TableCell>
										<TableCell align="left">Quotation Number</TableCell>
										<TableCell align="left">Customer Name</TableCell>
										<TableCell align="left">Plate Number</TableCell>
										<TableCell align="left">Payment Amount</TableCell>
										<TableCell align="left">Record Type</TableCell>
										<TableCell align="left">Mobile Number</TableCell>
										<TableCell align="left">Status</TableCell>
										<TableCell align="left">Payment Status</TableCell>
										<TableCell align="center">Action</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{data?.map((row) => {
										return (
											<>
												{row?.plateCode && (
													<TableRow hover role="checkbox" key={row?.id}>
														<TableCell align="left">{row?.id}{row?.accountStatus === "Inactivated" && (
																<Chip label="Inactive" color="error" size="small" />
															)}</TableCell>
														<TableCell align="left">{row?.quotationNumber}</TableCell>
														<TableCell align="left">{row?.customerName}</TableCell>
														<TableCell align="left">{row?.plateCode + ' ' + row?.plateNumber}</TableCell>
														<TableCell align="left">{row?.paymentAmount || '-'}</TableCell>
														<TableCell align="left">{row?.recordType || 'Automatic'}</TableCell>
														<TableCell align="left">{row?.mobileNumber}</TableCell>
														<TableCell align="left">
															<span style={{ color: quotationStatusClass(row?.paymentStatus), fontWeight: 600 }}>
																{capitalizeFirstLetter(row?.paymentStatus?.split('_').join(' ').toLowerCase())}
															</span>
															</TableCell>
															<TableCell align="left">
															{row?.paymentStatus != 'EXPIRED' && (<Chip color={row?.paymentProgressStatus == 'PAID' ? 'success' : 'warning'} label={capitalizeFirstLetter(row?.paymentProgressStatus?.split('_').join(' ').toLowerCase())}/>)}
															{row?.paymentStatus == 'EXPIRED' && (<Chip color="error" label="Expired"/>)}
								
															</TableCell>
															
														

														<TableCell align="center">
															<Tooltip title="View">
																<IconButton size="small" onClick={(event) => handleView(event, row)}>
																	{!openingView && <ArticleOutlinedIcon fontSize="small" />}
																	{openingView === row?.id && <CircularProgress size={20} />}
																</IconButton>
															</Tooltip>
															
															{row?.recordType != "Manual" && row?.accountStatus != "Inactivated" && row?.paymentStatus=="APPROVED" && row?.paymentProgressStatus=="WAITING_PAYMENT" && canEdit && (
																<Tooltip title="Decline">
																<IconButton
																	color="warning"
																	size="small"
																	onClick={(event) => handleDeclineButton(row?.id)}
																>
																	<BlockIcon fontSize="small" />
																</IconButton>
															</Tooltip>
															)}
															{row?.recordType != "Manual" && row?.accountStatus != "Inactivated" && row?.paymentStatus === 'WAITING_APPROVAL' && canEdit && (
																<>
																	<Tooltip title="Approve">
																		<IconButton
																			color="success"
																			size="small"
																			onClick={(event) => handleApproveButtonClick(row)}
																		>
																			<CheckIcon fontSize="small" />
																		</IconButton>
																	</Tooltip>
																	<Tooltip title="Decline">
																		<IconButton
																			color="warning"
																			size="small"
																			onClick={(event) => handleDeclineButton(row?.id)}
																		>
																			<BlockIcon fontSize="small" />
																		</IconButton>
																	</Tooltip>
																</>
															)}
														</TableCell>
													</TableRow>
												)}
											</>
										);
									})}
								</TableBody>
							</Table>
						</TableContainer>
						<TablePagination
							rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]}
							component="div"
							count={allQuotations}
							rowsPerPage={quotationDataLimit}
							page={quotationDataPage === 0 ? 0 : quotationDataPage - 1}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					</>
				)}
			</Paper>

			<Dialog
				open={openDeclineDialog}
				onClose={() => {
					setOpenDeclineDialog(false);
				}}
			>
				{/* <DialogTitle>Decline Reason</DialogTitle> */}
				<DialogContent>
					{/* <DialogContentText>Please write delcine reason.</DialogContentText> */}
					<TextField
						autoFocus
						margin="dense"
						id="declineReason"
						label="Reason"
						name="declineReason"
						type="text"
						onChange={handleValueChanged}
						fullWidth
						variant="standard"
					/>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => {
							setOpenDeclineDialog(false);
						}}
					>
						Cancel
					</Button>
					<Button
						onClick={() => {
							handleDecline(selectedRow.quotationId, selectedRow.declineReason);
							setOpenDeclineDialog(false);
						}}
					>
						Submit
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={openApprovalDialog}
				onClose={() => {
					setOpenApprovalDialog(false);
				}}
			>
				{/* <DialogTitle>Decline Reason</DialogTitle> */}
				<DialogContent>
					{/* <DialogContentText>Please write delcine reason.</DialogContentText> */}
					{/* <TextField
						autoFocus
						margin="dense"
						id="declineReason"
						label="Reason"
						name="declineReason"
						type="text"
						onChange={handleValueChanged}
						fullWidth
						variant="standard"
					/> */}
					<p> Are you sure want to approve the quotation?</p>
					{selectedApproveRow.quotationNumber && (
						<p>
							<strong>{selectedApproveRow.quotationNumber}</strong>
						</p>
					)}
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => {
							setOpenApprovalDialog(false);
						}}
					>
						Cancel
					</Button>
					<Button
						color="success"
						onClick={() => {
							console.log(selectedApproveRow);
							handleApprove(selectedApproveRow.quotationId);
							setOpenApprovalDialog(false);
						}}
					>
						Approve
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default QuotationTable;
