import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Container } from '@mui/material';

export default function ExportAgent(props) {
	const { onClose, open, type } = props;
	const [date_from, setDateFrom] = useState('');
	const [date_to, setDateTo] = useState('');

	const handleDateFromChanged = (event) => {
		setDateFrom(event.target.value);
	};
	const handleDateToChanged = (event) => {
		setDateTo(event.target.value);
	};
	const handleExport = () => {
		let url = `${process.env.REACT_APP_AGENT_API_URL}/agent/export/agent-list`;
		if (date_from && date_to) {
			url += '?date_from=' + date_from;
			url += '&date_to=' + date_to;
		} else {
			window.alert('Please select date from and date to');
			return false;
		}
		url += '&with_premium=true';
		console.log(url);
		window.open(url);
	};
	return (
		<div>
			<Dialog open={open} onClose={onClose}>
				<DialogTitle>Export Agents</DialogTitle>
				<DialogContent>
					<DialogContentText>To Download choose filters.</DialogContentText>
					<Container>
						<TextField
							autoFocus
							margin="dense"
							id="date_from"
							InputLabelProps={{ shrink: true }}
							label="Sign Up Date From"
							type="date"
							defaultValue={''}
							fullWidth
							variant="standard"
							onChange={handleDateFromChanged}
						/>
						<TextField
							autoFocus
							margin="dense"
							id="date_to"
							InputLabelProps={{ shrink: true }}
							label="Sign Up Date To"
							defaultValue={''}
							type="date"
							fullWidth
							variant="standard"
							onChange={handleDateToChanged}
						/>
					</Container>
				</DialogContent>
				<DialogActions>
					<Button onClick={onClose}>Cancel</Button>
					<Button onClick={handleExport}>Export</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
