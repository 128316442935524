import React, { useState, useEffect , useCallback} from 'react';
import { Button, Grid, IconButton, Paper, Typography, Box } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import PostLogin from '../../../layout/post-login';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import * as XLSX from 'xlsx';
import Loader from '../../../components/Loader';
import VehicleTable from './vehicleTable';
import quotationService from '../../../services/quotation.service';
import { useSnackbar } from 'notistack';


const VehicleUpload = (props) =>{
    const header= [{label:'Brand', key:"brandName"},{label:'Code', key:'code'},{label:'Model', key:'modelName'},{label:'SEAT CAPACITY', key:'seatCapacity'},{label:'VECHICLE CATEGORY', key:'vehicleCategory'},{label:'VEHICLE TYPE', key:"vehicleType"},{label:"VEHICLE CATEGORY CODE", key:'vehicleCategoryCode'},{label:'ENGINE', key:'engine'},{label:'HIGHVALUE', key:'highValue'},{label:'COUNTRY', key:'country'},{label:2025, key:2025},{label:2024, key:2024},{label:2023, key:2023},{label:2022, key:2022},{label:2021, key:2021},{label:2020, key:2020}, {label:2019, key:2019}, {label:2018, key:2018}, {label:2017, key:2017}, {label:2016, key:2016},{label:2015, key:2015}, {label:2014, key:2014}, {label:2013, key:2013}, {label:2012, key:2012}, {label:2011, key:2011}, {label:2010, key:2010}, {label:2009, key:2009}, {label:2008, key:2008}, {label:2007, key:2007}, {label:2006, key:2006}, {label:2005, key:2005}]
    const selectionTabs = [{name:'CAR', code:'A'}, {name:'MOTORCYCLE ≤ 250cc', code: 'D'},{name:'MOTORCYCLE > 250cc', code:"D"}, {name:'PICK UP', code:"P"},{name:"TRUCK", code:"C"}]
	const { children, onClose } = props;
    const [dragOver, setDragOver] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showTable, setShowTable] = useState(false);
    const [totalData, setTotalData] = useState(null);
    const [headerRowData, setHeaderRowData] = useState(null);
    const [buttonLoader, setButtonLoader] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

        const headerKey = (headerLabel) => {
         const key = header.find((head) => head.label === headerLabel)
          if(key){
              return key.key
          }
          return null
        }
        const handleCancelClick=(event) =>{
            setShowTable(false)
             setTotalData([])
            setHeaderRowData([])
            event.preventDefault()
            document.getElementById('raised-button-file').value = null
        }
        const onFileUpload = (evt) => {
            console.log('inside file change')
            setIsLoading(prev => true);
            const f = evt.target.files[0];
            
            const reader = new FileReader();
            reader.onload = (evt) => { // evt = on_file_select event
                /* Parse data */
                const bstr = evt.target.result;
                const wb = XLSX.read(bstr, {type:'binary'});
                /* Get first worksheet */
                console.log(wb.SheetNames)
                let totalDataArray=[]
                const selectedTabNames = selectionTabs.map(tab => tab.name)
                let headerRow = []
                for(const sheet of wb.SheetNames){
                   
                    if(selectedTabNames.includes(sheet)){
                       
                        const ws = wb.Sheets[sheet];
                      
                        let data =  XLSX.utils.sheet_to_json(ws, {header:1, skipRow: 3}).filter((row) => {console.log(row) ; return row.length > 1})
                        //data = data.slice(3, data.length-1)
                        headerRow = data[0].slice() 
                        headerRow.splice(3, 0, 'VEHICLE CATEGORY CODE')
                        const vehicleCategoryCode=selectionTabs.find(tab => tab.name === sheet).code
                        const mappedData = data.map((row, index) => {
                            // let obj = {vehicleCategoryCode: selectionTabs.find(tab => tab.name === sheet).code}
                            // headerRow.forEach((header, index) => {
                            //     obj[header] = row[index]
                            // })
                            // return obj
                            row.splice(3, 0,vehicleCategoryCode )
                            return row
                        })
                        totalDataArray.push(...mappedData.slice(1, mappedData.length))

                        // totalDataArray.push(...data.slice(1, data.length))
                        
                        
                    }
                }
                setTotalData(totalDataArray)
                setHeaderRowData(headerRow)
                setIsLoading(false);
                setShowTable(true);
                };
            reader.readAsArrayBuffer(f);
         
        }
        const handleDragOver = ((event) => {
          event.preventDefault();
          setDragOver(true);
        });
      
        const handleDragLeave = ((event) => {
          event.preventDefault();
          setDragOver(false);
        });
      
        const handleDrop = (
          (event) => {
            console.log('inside drop')
            event.preventDefault();
            setDragOver(false);
            if (event.dataTransfer.files && event.dataTransfer.files[0]) {
              onFileUpload(event);
            }
          })
      
        const handleChange = (event) => {
            console.log('inside change')
            if (event.target.files && event.target.files[0]) {
              onFileUpload(event);
            }
          }
        const handleUpload = () => {
          setButtonLoader(true)
          const requestBody=[]
          totalData.forEach((data) => {
              let obj = {}
              const prices = []
              headerRowData.map((header, index) => {
                if(typeof header == "number"){
                  const yearObj = {}
                  yearObj['year'] = header
                  yearObj['price'] = data[index]*10*10*10*10*10*10 || 0
                  prices.push(yearObj)
                } else {
                  const key = headerKey(header)
                  if(key){
                    obj[key === 'truk' ? 'truck' : key] = data[index] || 0
                    if(key === 'brandName'){
                      obj['brandCode'] = data[index].replace(/\s/g, '')
                  }
                }
              }
              
              })
              obj['prices'] = prices

              requestBody.push(obj)
          })
          requestBody.forEach((data) => {
            if (
              data['vehicleCategoryCode'] === 'D' &&
              data['brandName'] === 'HONDA'
            ) {
              data['brandCode'] = 'HONDAMOTORCYCLE';
            }
            const vehicleType = data['vehicleCategory'];
            const vehicleCategory = data['vehicleType'];
            let wheelerType;
            if (data['vehicleCategoryCode'] === 'A') {
              wheelerType = 'fourWheeler';
            } else if (data['vehicleCategoryCode'] === 'D') {
              wheelerType = 'twoWheeler';
            } else if (data['vehicleCategoryCode'] === 'C') {
              wheelerType = 'truck';
            } else if (data['vehicleCategoryCode'] === 'P') {
              wheelerType = 'pickup';
            } else if (data['vehicleCategoryCode'] === 'B') {
              wheelerType = 'bus';
            }
            data['isPrivate']= true
            data['isCommercial']= true
            data['isComprehensive']= true
            data['isTLO']= true
            data['wheelerType']=wheelerType
            data['isHighValue']= data['highValue'] === 'YES'
            data['vehicleCategory'] = vehicleCategory === 'TRUK' ? 'Truck / Pick Up' : vehicleCategory
            data['vehicleType'] = vehicleType === 'TRUK' ? 'Truck' : vehicleType
          })
          console.log(requestBody)
          quotationService.sendVehicleData(requestBody).then((response) => {
            console.log(response)
            setButtonLoader(false)
            setShowTable(false)
            setTotalData([])
            setHeaderRowData([])
            document.getElementById('raised-button-file').value = null
            enqueueSnackbar('Vehicle Data Uploaded Successfully', { variant: 'success' });
          }).catch((error) => {
            console.log(error)
            setButtonLoader(false)
            enqueueSnackbar('Error in Uploading Vehicle Data', { variant: 'error' });
          })
        }
        
	return (
        <PostLogin>
       
		<Grid container direction="row" justifyContent="space-between" alignItems="center" p={3} pb={0}>
			<Typography variant="h5">Upload Vehicle File</Typography>
			
		</Grid>
        <Grid>
        <Paper
      variant="outlined"
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      style={{
        border: dragOver ? '2px dashed #000' : '2px dashed #aaa',
        padding: 20,
        textAlign: 'center',
        cursor: 'pointer',
        background: dragOver ? '#eee' : '#fafafa',
      }}
    >
      <input
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        style={{ display: 'none' }}
        id="raised-button-file"
        multiple
        type="file"
        onChange={(e)=>handleChange(e)}
      />
      <label htmlFor="raised-button-file">
        <Box display="flex" flexDirection="column" alignItems="center">
          <IconButton color="primary" aria-label="upload picture" component="span" >
            <CloudUploadIcon style={{ fontSize: 60 }} />
          </IconButton>
          <Typography>Drag and drop files here or click to select files</Typography>
        </Box>
      </label>
    </Paper>
    </Grid>


    {isLoading && (
        <Grid container direction="row"  alignItems="center" p={3} pb={0}>
            <Typography variant="h5">Reading...  </Typography><CircularProgress />
        
        </Grid>
        
    )}
       {
        showTable && (
            <Grid container direction="row"  alignItems="center" p={3} pb={0}>
                <VehicleTable vehicleData={totalData} header={headerRowData} />
            </Grid>
        )
       }

       {
        showTable && (
            <Grid container direction="row" justifyContent="end" alignItems="center" p={3} pb={0} margin={2}>
                <Button variant="contained" color="primary" margin={2} onClick={handleUpload} disabled={buttonLoader}>Upload{buttonLoader && '...'} {buttonLoader && <CircularProgress size={10} style={{marginLeft: '3px'}}/>}</Button>
                <Button variant="contained" color="error" onClick={handleCancelClick} disabled={buttonLoader}>Cancel</Button>
            </Grid>
        )
       }
       
        </PostLogin>
	);
};

export default VehicleUpload;