import React, { useState, useEffect } from 'react';
import {
	IconButton,
	TableCell,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Paper,
	Tooltip,
	Chip,
	Switch,
} from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import AddModerator from '@mui/icons-material/AddModerator';
import Loader from '../../../components/Loader';
import authService from '../../../services/auth.service';

const CompanyTable = ({
	data,
	handleEdit,
	handleDisable,
	setcompanyDataPage,
	setcompanyDataLimit,
	companyDataPage,
	companyDataLimit,
	handleAddPlan,
	handleToggle,
}) => {
	// console.log(data)
	const [gridLoader, setGridLoader] = useState(false);
	const [canEdit, setCanEdit] = useState(true);

	useEffect(() => {
		setGridLoader(false);
		const user = authService.getCurrentUser();
		if (user?.permissions && user?.permissions === 'read-only') {
			setCanEdit(false);
		}
	}, [data]);

	const handleChangePage = (event, newPage) => {
		setGridLoader(true);
		setcompanyDataPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setGridLoader(true);
		setcompanyDataPage(0);
		setcompanyDataLimit(+event.target.value);
	};

	return (
		<Paper sx={{ position: 'relative', overflowX: 'clip' }}>
			<>
				{(!data || gridLoader) && <Loader showLoader />}
				<TableContainer>
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow>
								<TableCell align="left">Logo</TableCell>
								<TableCell align="left">Name</TableCell>
								{canEdit && <TableCell align="left">Toggle</TableCell>}
								<TableCell align="center">Status</TableCell>
								{canEdit && <TableCell align="center">Action</TableCell>}
							</TableRow>
						</TableHead>
						<TableBody>
							{(!data || data?.data.length == 0) && (
								<TableRow hover role="checkbox">
									<TableCell align="center"> No data found</TableCell>
								</TableRow>
							)}
							{data?.data.map((row) => (
								<TableRow hover role="checkbox" key={row?.id}>
									<TableCell align="left">
										<img
											src={`${row?.companyLogo}`}
											width="80px"
											style={{
												borderRadius: '5px',
												border: '1px solid lightgray',
												width: '60px',
												height: '60px',
												objectFit: 'cover',
											}}
										/>
									</TableCell>
									<TableCell align="left">{row?.companyName}</TableCell>
									{canEdit && (
										<TableCell align="left">
											<Switch
												checked={row?.isActive}
												onChange={(event) => handleToggle(event, row)}
												name="loading"
												color="primary"
												key={row?.id}
											/>
										</TableCell>
									)}
									<TableCell align="center">
										<Chip
											label={row?.isActive ? 'Enabled' : 'Disabled'}
											size="small"
											color={row?.isActive ? 'success' : 'primary'}
										/>
									</TableCell>
									{canEdit && (
										<TableCell align="center">
											{/* <Tooltip title='Add Insurance Plan'>
                          <IconButton size='small' onClick={(event) => handleAddPlan(event, row)}>
                            <AddModerator fontSize='small' />
                          </IconButton>
                        </Tooltip> */}
											<Tooltip title="Edit">
												<IconButton size="small" onClick={(event) => handleEdit(event, row)}>
													<EditOutlinedIcon fontSize="small" />
												</IconButton>
											</Tooltip>
											<Tooltip title="Disable">
												<IconButton size="small" onClick={(event) => handleDisable(event, row)}>
													<DeleteOutlinedIcon fontSize="small" />
												</IconButton>
											</Tooltip>
										</TableCell>
									)}
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[10, 25, 50, 100]}
					component="div"
					count={data?.totalRecords || 10}
					rowsPerPage={companyDataLimit}
					page={companyDataPage}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</>
		</Paper>
	);
};

export default CompanyTable;
