import React, { useState, useEffect } from 'react';
import AuthService from '../../../../services/auth.service';
import EventBus from '../../../../common/EventBus';
import { Link, NavLink } from 'react-router-dom';

import { Box, Drawer, useMediaQuery, Button } from '@mui/material';
import List from '@mui/icons-material/List';
import Close from '@mui/icons-material/Close';
import { Logo } from '../../../../assets/images/logo';

import { DividerLine, LhsWrapper, LogoDiv, ListWrapper, SubMenu } from './style';

const items = [
	/* {
		href: '/dashboard',
		title: 'Dashboard',
	}, */
	{
		href: '/agents',
		title: 'Agents',
	},
	{
		href: '/agent-tree',
		title: 'Agents Tree',
	},
	{
		href: '/quotations',
		title: 'Quotations',
	},
	{
		href: '/waiting-approvals',
		title: 'Waiting Approval Quotations',
	},
	{
		href: '/products',
		title: 'Products',
	},
	{
		href: '/companies',
		title: 'Companies',
	},
	{
		href: '/insurance-plan',
		title: 'Insurance Plans',
	},
	{
		href: '/payments',
		title: 'Payments',
	},
	{
		href: '/claim',
		title: 'Claim',
	},
	{
		href: '/policy',
		title: 'Policy',
	},
	{
		href:'/carrier-users',
		title:"Carrier Users"
	},
	{
		href:'/banner-images',
		title:"Banner Images"
	},
	{
		href:'/vehicle-upload',
		title:"Vehicle List Upload"
	}
];

const Lhs = (props) => {
	const { open, onClose } = props;
	const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
		defaultMatches: true,
		noSsr: false,
	});

	// const [showModeratorBoard, setShowModeratorBoard] = useState(false)
	// const [showAdminBoard, setShowAdminBoard] = useState(false)
	// const [currentUser, setCurrentUser] = useState(undefined)
	// const [activeLink, setActiveLink] = useState('')

	useEffect(() => {
		const user = AuthService.getCurrentUser();
		if (user) {
			// setCurrentUser(user)
			// setShowModeratorBoard(user.roles.includes('ROLE_MODERATOR'))
			// setShowAdminBoard(user.roles.includes('ROLE_ADMIN'))
		}

		EventBus.on('logout', () => {
			logOut();
		});

		return () => {
			EventBus.remove('logout');
		};
	}, []);

	const logOut = () => {
		AuthService.logout();
		// setShowModeratorBoard(false)
		// setShowAdminBoard(false)
		// setCurrentUser(undefined)
	};

	// const selectFunc = (linkUrl) => {
	//   if (linkUrl === url) {
	//     return 'active'
	//   }
	//   return ''
	// }

	const [active, setActive] = useState({});

	const content = (
		<LhsWrapper>
			<Box>
				<LogoDiv>
					<Link to="/">
						<Logo height="50" />
					</Link>
				</LogoDiv>
			</Box>
			<DividerLine />
			<Box sx={{ py: 3 }}>
				{items.map((item, i) => (
					<ListWrapper key={i} active={item.title}>
						<NavLink
							to={item.href}
							onClick={() => {
								setActive(item);
								console.log(item);
							}}
							className={item.title === active.title ? 'active' : ''}
						>
							{' '}
							{item.title}
						</NavLink>
					</ListWrapper>
				))}
				<ListWrapper>
					<Link to="/login" onClick={logOut}>
						Log Out
					</Link>
				</ListWrapper>
			</Box>
		</LhsWrapper>
	);
	if (lgUp) {
		return (
			<Drawer
				anchor="left"
				open
				PaperProps={{
					sx: {
						backgroundColor: 'primary.main',
						color: '#FFFFFF',
						width: 280,
					},
				}}
				variant="permanent"
			>
				{content}
			</Drawer>
		);
	}
	return (
		<Drawer
			anchor="left"
			onClose={onClose}
			open={open}
			PaperProps={{
				sx: {
					backgroundColor: 'primary.main',
					color: '#FFFFFF',
					width: 280,
				},
			}}
			sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
			variant="temporary"
		>
			{content}
		</Drawer>
	);
};

export default Lhs;
