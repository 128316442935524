import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

export const LhsWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%'
})

export const LogoDiv = styled(Box)({
  padding: '10px'
})

export const DividerLine = styled(Box)({
  margin: '0',
  flexShrink: '0',
  border: '0 solid #fff',
  borderBottomWidth: 'thin',
  marginTop: '-11px',
  marginBottom: '20px'
})

const newLocal = `
/* The subnavigation menu */
.subnav {
  float: left;
  overflow: hidden;
}



/* Style the subnav content - positioned absolute */
.subnav-content {
  display: none;
  position: absolute;
  left: 0;
  background-color: red;
  width: 100%;
  z-index: 1;
  border-radius: 20px;
}

/* Style the subnav links */
.subnav-content a {
  float: left;
  color: white;
  text-decoration: none;
}

/* Add a grey background color on hover */
.subnav-content a:hover {
  background-color: #eee;
  color: black;
}
`
export const SubMenu = styled(Box)({newLocal})

export const ListWrapper = styled(Box)(({ theme }) => `
    padding: 5px 10px;
    width: 100%;
    display: flex;

    /* The subnavigation menu */
      .subnav {
        float: left;
        overflow: hidden;
      }



      /* Style the subnav content - positioned absolute */
      .subnav-content {
        display: block;
        position: absolute;
        left: 0;
        background-color: red;
        width: 80%;
        z-index: 1;
        border-radius: 15px;
        margin: 12% 15%;
      }

      /* Style the subnav links */
      .subnav-content a {
        float: left;
        color: #fff;
        text-decoration: none;
      }

      /* Add a grey background color on hover */
      .subnav-content a:hover {
        background-color: #fff;
        color: #e95f2d;
      }
    /* When you move the mouse over the subnav container, open the subnav content */
    
    & a {
        text-decoration: none;
        color: ${theme.palette.primary.contrastText};
        width:100%;
        padding: 10px 15px 10px 20px;
        border-radius: 20px;
        transition: all .4s ease;
    }

    & a:hover {
        background: #fff;
        color: #e95f2d;
        box-shadow: 2px 2px 4px 0px #333;
    }
    .active {
        text-decoration: none;
        background: #fff;
        color: #e95f2d;
        box-shadow: 2px 2px 4px 0px #333;
        width:100%;
        padding: 10px 15px 10px 20px;
        border-radius: 20px;
        transition: all .4s ease;
    }
    .subnav .subnav-content .active {
      text-decoration: none;
      background: #fff;
      color: #e95f2d;
      box-shadow: 2px 2px 4px 0px #333;
      width:100%;
      padding: 10px 15px 10px 20px;
      border-radius: 20px;
      transition: all .4s ease;
  }
`)
