import React, { useState } from 'react';
import { useFormik } from 'formik';
import { Button, Dialog, DialogContent, IconButton, TextField, Typography, Grid } from '@mui/material';
import { Close } from '@mui/icons-material';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

import AgentService from '../../../services/agent.service';

const DialogTitle = (props) => {
	const { children, onClose } = props;
	return (
		<Grid container direction="row" justifyContent="space-between" alignItems="center" p={3} pb={0}>
			<Typography variant="h5">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" onClick={onClose}>
					<Close />
				</IconButton>
			) : null}
		</Grid>
	);
};

const AgentForm = (props) => {
	const { onClose, open, data, mode, refetch } = props;
	const { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = useState(false);

	const validation = (values) => {
		const errors = {};
		return errors;
	};

	const onSubmit = (backendData) => {
		setLoading(true);
		if (mode === 'Add') {
			AgentService.createAgent(backendData)
				.then((resp) => {
					setLoading(false);
					enqueueSnackbar('Changes made have been successfully saved', {
						variant: 'success',
					});
					refetch();
					onClose();
				})
				.catch((err) => {
					setLoading(false);
					if (err?.response?.data) {
						enqueueSnackbar(err.response.data.error_message, {
							variant: 'error',
						});
					} else {
						enqueueSnackbar(err.error_message, { variant: 'error' });
					}
				});
		} else {
			AgentService.updateAgent(data?.id, backendData)
				.then(() => {
					setLoading(false);

					enqueueSnackbar('Changes made have been successfully saved', {
						variant: 'success',
					});
					refetch();
					onClose();
				})
				.catch((err) => {
					setLoading(false);

					console.log(err);
					if (err?.response?.data) {
						enqueueSnackbar(err.response.data.message || err.response.data.error_message, {
							variant: 'error',
						});
					} else {
						enqueueSnackbar(err.error_message || 'Unable to update agent', { variant: 'error' });
					}
				});
		}
	};

	const formik = useFormik({
		validateOnChange: false,
		validateOnBlur: false,
		initialValues: {
			name: data?.name || '',
			email: data?.email || '',
			phone: data?.phone || '',
			referred_by: data?.referred_by_name || '',
			referral_code: data?.referral_code || '',
		},
		validationSchema: Yup.object({
			name: Yup.string().max(25).required('Name is required'),
			email: Yup.string().required('Email is required'),
			phone: Yup.number().required('Phone is required'),
			// referred_by: Yup.string().max(25).required('Referral By is required'),
			referral_code: Yup.string().max(25).required('Referral Code is required'),
		}),
		validate: (values) => validation(values),
		onSubmit: (values) => {
			const updatedData = { ...values };
			onSubmit(updatedData);
		},
	});

	return (
		<Dialog onClose={onClose} aria-labelledby="add-agent" open={open} maxWidth="sm" fullWidth>
			<DialogTitle id="add-agent" onClose={onClose}>
				{`${mode} Agent`}
			</DialogTitle>

			<DialogContent>
				<TextField
					error={Boolean(formik.touched.name && formik.errors.name)}
					fullWidth
					helperText={formik.touched.name && formik.errors.name}
					margin="normal"
					name="name"
					label="Name"
					onBlur={formik.handleBlur}
					onChange={formik.handleChange}
					type="text"
					value={formik.values.name}
					variant="outlined"
				/>

				<TextField
					error={Boolean(formik.touched.email && formik.errors.email)}
					fullWidth
					helperText={formik.touched.email && formik.errors.email}
					margin="normal"
					name="email"
					label="Email"
					onBlur={formik.handleBlur}
					onChange={formik.handleChange}
					type="text"
					value={formik.values.email}
					variant="outlined"
				/>

				<TextField
					error={Boolean(formik.touched.phone && formik.errors.phone)}
					fullWidth
					helperText={formik.touched.phone && formik.errors.phone}
					margin="normal"
					name="phone"
					label="Phone"
					onBlur={formik.handleBlur}
					onChange={formik.handleChange}
					type="text"
					value={formik.values.phone}
					variant="outlined"
					// disabled={mode === "Add" ? false : true}
					disabled={mode !== 'Add'}
				/>

				<TextField
					error={Boolean(formik.touched.referred_by && formik.errors.referred_by)}
					fullWidth
					helperText={formik.touched.referred_by && formik.errors.referred_by}
					margin="normal"
					name="referred_by"
					label="Referral By"
					onBlur={formik.handleBlur}
					onChange={formik.handleChange}
					type="text"
					value={formik.values.referred_by}
					variant="outlined"
				/>

				<TextField
					error={Boolean(formik.touched.referral_code && formik.errors.referral_code)}
					fullWidth
					helperText={formik.touched.referral_code && formik.errors.referral_code}
					margin="normal"
					name="referral_code"
					label="Referral Code"
					onBlur={formik.handleBlur}
					onChange={formik.handleChange}
					type="text"
					value={formik.values.referral_code}
					variant="outlined"
					disabled={mode !== 'Add'}
				/>
			</DialogContent>

			<Grid container direction="row" justifyContent="flex-end" alignItems="center" p={3} pt={0}>
				<Button variant="contained" onClick={formik.handleSubmit} color="primary" disable={loading}>
					Submit
				</Button>
			</Grid>
		</Dialog>
	);
};

export default AgentForm;
