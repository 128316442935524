import React from 'react'
// import styled from '@emotion/styled'
import {
  // AppBar, Avatar, Badge, Box, IconButton, Toolbar,
  Tooltip, Typography
} from '@mui/material'
// import SearchIcon from '@mui/icons-material/Search'
// import MenuIcon from '@mui/icons-material/Menu'
// import PeopleIcon from '@mui/icons-material/People'
import PersonIcon from '@mui/icons-material/Person'
// import NotificationsIcon from '@mui/icons-material/Notifications'
import AuthService from '../../../../services/auth.service'
import {
  ToolbarStyled,
  IconButtonStyled,
  BoxStyled,
  DashboardNavbarRoot
} from './style'

export const DashboardNavbar = (props) => {
  const { onSidebarOpen, ...other } = props
  const currentUser = AuthService.getCurrentUser()

  return (
    <DashboardNavbarRoot
      {...other}
    >
      <ToolbarStyled
        disableGutters
      >
        {/* <IconButton
            onClick={onSidebarOpen}
          >
            <MenuIcon fontSize='small' />
          </IconButton> */}

        {/* <Tooltip title='Search'>
            <IconButton >
              <SearchIcon fontSize='small' />
            </IconButton>
          </Tooltip> */}

        {/* <BoxStyled /> */}

        {/* <Tooltip title='Contacts'>
          <IconButtonStyled >
            <PeopleIcon fontSize='small' />
          </IconButtonStyled>
        </Tooltip>
        <Tooltip title='Notifications'>
          <IconButtonStyled >
            <Badge
              badgeContent={4}
              color='primary'
              variant='dot'
            >
              <NotificationsIcon fontSize='small' />
            </Badge>
          </IconButtonStyled>
        </Tooltip> */}

        {currentUser.name && <Typography variant='subtitle2' color='primary'>{currentUser.name}</Typography>}
        <Tooltip title={currentUser.name || 'User'}>
          <BoxStyled>
            <IconButtonStyled>
              <PersonIcon fontSize='small' />
            </IconButtonStyled>
          </BoxStyled>
        </Tooltip>
      </ToolbarStyled>
    </DashboardNavbarRoot>
  )
}
