import React, { useState, useEffect } from 'react';
import {
	IconButton,
	TableCell,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Paper,
	Tooltip,
	Switch,
	Chip,
} from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Loader from '../../../components/Loader';
import { Rating } from '@mui/material';
import authService from '../../../services/auth.service';

const ProductTable = ({
	data,
	handleEdit,
	handleDelete,
	setProductDataPage,
	setProductDataLimit,
	productDataPage,
	productDataLimit,
	handleToggle,
}) => {
	const [gridLoader, setGridLoader] = useState(false);
	const [canEdit, setCanEdit] = useState(true);

	useEffect(() => {
		setGridLoader(false);
		const user = authService.getCurrentUser();
		if (user?.permissions && user?.permissions === 'read-only') {
			setCanEdit(false);
		}
	}, [data?.data]);

	const handleChangePage = (event, newPage) => {
		let page = newPage;
		setGridLoader(true);
		setProductDataPage(page);
	};

	const handleChangeRowsPerPage = (event) => {
		setGridLoader(true);
		setProductDataPage(0);
		setProductDataLimit(+event.target.value);
	};

	const changeVehicleType = (type) => {
		let chips = '';
		if (typeof type == 'object') {
			return type.map((e) => <Chip label={e} size="small" color={'primary'} key={e} />);
		}

		return <Chip label={type} size="small" color={'primary'} key={type} />;
	};

	return (
		<Paper sx={{ position: 'relative', overflowX: 'clip' }}>
			<>
				<TableContainer>
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow>
								<TableCell align="left">Display Name</TableCell>
								<TableCell align="left">Code</TableCell>
								<TableCell align="left">Vehicle Category</TableCell>
								{canEdit && <TableCell align="left">Active</TableCell>}
								<TableCell align="left">Rating</TableCell>
								{canEdit && <TableCell align="center">Action</TableCell>}
							</TableRow>
						</TableHead>
						<TableBody>
							{data?.data?.map((index) => (
								<TableRow hover role="checkbox" key={index?._id}>
									<TableCell align="left">{index?.displayName}</TableCell>
									<TableCell align="left">{index?.code}</TableCell>
									<TableCell align="left">{changeVehicleType(index?.vehicleType)}</TableCell>
									{canEdit && (
										<TableCell align="left">
											<Switch
												checked={index?.isActive}
												onChange={(event) => handleToggle(event, index)}
												name="loading"
												color="primary"
											/>
										</TableCell>
									)}
									<TableCell align="left">
										<Rating
											size="small"
											color="primary"
											value={index?.rating}
											readOnly={true}
											max={5}
											precision={0.5}
										/>
									</TableCell>
									{canEdit && (
										<TableCell align="center">
											<Tooltip title="Edit">
												<IconButton size="small" onClick={(event) => handleEdit(event, index)}>
													<EditOutlinedIcon fontSize="small" />
												</IconButton>
											</Tooltip>
											<Tooltip title="Delete">
												<IconButton size="small" onClick={(event) => handleDelete(event, index)}>
													<DeleteOutlinedIcon fontSize="small" />
												</IconButton>
											</Tooltip>
										</TableCell>
									)}
								</TableRow>
							))}
							{(!data?.data || data?.data?.length == 0) && (
								<TableRow hover role="checkbox">
									<TableCell align="center" colSpan={6}>
										No Data Found
									</TableCell>
								</TableRow>
							)}
							{(!data?.data || gridLoader) && (
								<TableRow hover role="checkbox">
									<TableCell align="center" colSpan={6}>
										<Loader showLoader />
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[10, 25, 50, 100]}
					component="div"
					count={data?.totalRecords || 10}
					rowsPerPage={productDataLimit}
					page={productDataPage}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</>
		</Paper>
	);
};

export default ProductTable;
