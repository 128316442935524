import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Typography } from '@mui/material';
import PostLogin from '../../layout/post-login';
import ClaimTable from './components/claimTable';
import ClaimView from './components/claimView';
import ClaimService from '../../services/claims.service';
import Loader from '../../components/Loader';

const ClaimComponent = (props) => {
	const { enqueueSnackbar } = useSnackbar();

	const [openClaimView, setOpenClaimView] = useState(false);
	const [claimData, setClaimData] = useState();
	const [selectedRow, setSelectedRow] = useState({});

	const [claimDataPage, setClaimDataPage] = useState(0);
	const [claimDataLimit, setClaimDataLimit] = useState(10);

	const loadClaimData = () => {
		ClaimService.getClaimsList(claimDataPage, claimDataLimit)
			.then((response) => {
				setClaimData(response?.data?.data);
			})
			.catch((err) => {
				if (err?.response?.data) {
					enqueueSnackbar(err.response.data.error_message, { variant: 'error' });
				} else {
					enqueueSnackbar(err.error_message, { variant: 'error' });
				}
			});
	};

	const handleView = (event, row) => {
		event.stopPropagation();
		setOpenClaimView(true);
		setSelectedRow(row);
	};

	const handleClose = () => {
		setOpenClaimView(false);
		setSelectedRow({});
	};

	useEffect(() => {
		loadClaimData();
	}, [claimDataPage, claimDataLimit]);

	return (
		<PostLogin>
			<Typography variant="h5" gutterBottom component="div">
				Claims Page{' '}
			</Typography>
			{!claimData && <Loader showLoader outlinedLoader />}
			<ClaimTable
				data={claimData}
				handleView={handleView}
				setClaimDataPage={setClaimDataPage}
				setClaimDataLimit={setClaimDataLimit}
				claimDataPage={claimDataPage}
				claimDataLimit={claimDataLimit}
			/>
			{openClaimView && (
				<ClaimView data={selectedRow} onClose={handleClose} refetch={loadClaimData} open={openClaimView} {...props} />
			)}
		</PostLogin>
	);
};

export default ClaimComponent;
