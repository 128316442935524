import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { Grid, Typography, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Confirmation } from '../../../components/Confirmation';
import PostLogin from '../../../layout/post-login';
import List from './list';
// import ProductForm from '../add/add'
import CompanyService from '../../../services/companies.service';
import authService from '../../../services/auth.service';
import Cookies from 'js-cookie';

const CompaniesList = (props) => {
	const { enqueueSnackbar } = useSnackbar();
	const [mode, setMode] = useState('');
	const [openConfirmation, setConfirmation] = useState(false);
	const [companyData, setcompanyData] = useState();
	const [selectedRow, setSelectedRow] = useState({});
	const [companyDataPage, setcompanyDataPage] = useState(1);
	const [companyDataLimit, setcompanyDataLimit] = useState(10);
	const [canEdit, setCanEdit] = useState(true);

	const loadCompanyData = () => {
		CompanyService.getCompaniesList(companyDataPage, companyDataLimit)
			.then((response) => {
				console.log(response?.data?.data);
				Cookies.set('CSRF-TOKEN', response.headers['xsrf-token']);
				setcompanyData(response?.data?.data);
			})
			.catch((err) => {
				setcompanyData({ data: { data: null } });
				if (err?.response?.data) {
					enqueueSnackbar(err.response.data.error_message, { variant: 'error' });
				} else {
					enqueueSnackbar(err.error_message, { variant: 'error' });
				}
			});
	};

	const handleEdit = (event, row) => {
		event.stopPropagation();
		setSelectedRow(row);
		setMode('Edit');
		console.log({
			pathname: '/companies/edit',
			state: { detail: row },
		});
		props.history.push({
			pathname: '/companies/edit',
			state: { detail: row },
		});
	};

	const handleDisable = (event, row) => {
		event.stopPropagation();
		setSelectedRow(row);
		setMode('Delete');
		setConfirmation(true);
	};

	const handleToggle = (event, row) => {
		event.stopPropagation();
		console.log(row);
		const xsrfToken = Cookies.get('CSRF-TOKEN');
		CompanyService.toggleCompanyStatus(row?.id, !row?.isActive, xsrfToken)
			.then((response) => {
				loadCompanyData();
				enqueueSnackbar(`${row?.companyName}'s company is status updated.`, { variant: 'success' });
			})
			.catch((err) => {
				enqueueSnackbar(
					`Some error occured while updating status of ${row?.companyName || 'this company'}.`,
					{ variant: 'error' },
					err
				);
			});
	};

	const handleClose = () => {
		setConfirmation(false);
		setSelectedRow({});
		// setMode('Add')
	};

	const handleAddPlan = (event, row) => {
		event.stopPropagation();
		setSelectedRow(row);
		setMode('Edit');
		props.history.push({
			pathname: '/insurance-plan/add',
			state: { companyId: row?.id },
		});
	};

	const confirmationDelete = () => {
		// setLoading(true)

		const xsrfToken = Cookies.get('CSRF-TOKEN');
		CompanyService.deleteCompany(selectedRow?.id, xsrfToken)
			.then((response) => {
				setConfirmation(false);
				// setLoading(false)
				loadCompanyData();
				enqueueSnackbar(`${selectedRow?.companyName}'s company is Deleted.`, { variant: 'success' });
			})
			.catch((err) => {
				setConfirmation(false);
				// setLoading(false)
				enqueueSnackbar(
					`Some error occured while deleting ${selectedRow?.companyName || 'this company'}.`,
					{ variant: 'error' },
					err
				);
			});
	};

	useEffect(() => {
		loadCompanyData();
		const user = authService.getCurrentUser();
		if (user?.permissions && user?.permissions === 'read-only') {
			setCanEdit(false);
		}
	}, [companyDataPage, companyDataLimit]);

	return (
		<PostLogin>
			<Grid container direction="row" justifyContent="space-between" alignItems="center">
				<Grid item>
					<Typography variant="h5" gutterBottom component="h5">
						Companies Page
					</Typography>
				</Grid>
				{canEdit && (
					<Grid item>
						<Link to="/companies/add">
							<Button variant="contained" size="small" startIcon={<AddIcon />}>
								Add Company
							</Button>
						</Link>
					</Grid>
				)}
			</Grid>
			<List
				data={companyData}
				handleEdit={handleEdit.bind(null)}
				handleDisable={handleDisable.bind(null)}
				setcompanyDataPage={setcompanyDataPage}
				setcompanyDataLimit={setcompanyDataLimit}
				companyDataPage={companyDataPage}
				companyDataLimit={companyDataLimit}
				handleAddPlan={handleAddPlan.bind(null)}
				handleToggle={handleToggle.bind(null)}
			/>

			{/* {
        openProductForm &&
          <ProductForm
            mode={mode}
            data={selectedRow}
            onClose={handleClose}
            refetch={loadcompanyData}
            open={openProductForm}
            {...props}
          />
      } */}

			{openConfirmation && (
				<Confirmation
					open={openConfirmation}
					title={`Are you sure you want to delete the company ${selectedRow?.companyName}?`}
					submitMsg={mode}
					onSubmit={() => {
						confirmationDelete();
					}}
					onClose={handleClose}
				/>
			)}
		</PostLogin>
	);
};

export default CompaniesList;
