import React, { useEffect, useState } from 'react';
import { Grid, MenuItem, InputLabel, IconButton, OutlinedInput, CircularProgress, Checkbox } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useSnackbar } from 'notistack';
import ProductService from '../../../services/product.service';
import MuiButton from '../../../components/MuiButton';
import MuiTextField from '../../../components/MuiTextField';
import FormHelperText from '@mui/material/FormHelperText';
import Error from '../../../components/Alert/Error';
// https://medium.com/@deshan.m/yup-formik-dynamic-array-of-object-form-validation-ed0afed3d886
import {
	ProductWrapper,
	SectionContentAddRow,
	SectionContent,
	FormControl,
	Title,
	FormControlCol1,
	FormControlCol3,
	FormControlPricing,
	FormControlRiders,
	FormControlThirdParties,
	AddButton,
} from './style';
import Cookies from 'js-cookie';

const ProductForm = (props) => {
	const { data, mode } = props;
	const { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = useState(false);

	const [companyNameLists, setcompanyNameLists] = useState([]);
	const [vehicleTypeList, setvehicleTypeList] = useState([]);
	const [vehicleCategoryList, setvehicleCategoryList] = useState([]);
	const [productTypeList, setproductTypeList] = useState([]);
	const [productcategoryList, setproductCategoryList] = useState([]);

	const [companyName, setCompanyName] = useState('');
	const [selectedProductCode, setSelectedProductCode] = useState('');
	const [insurancePlanNameLists, setinsurancePlanNameLists] = useState([]);
	const [insurancePlanName, setinsurancePlanName] = useState('');
	const [errorMessages, setErrorMessages] = useState([]);
	const [showErrorField, setShowErrorField] = useState(false);

	const [productField, setProductField] = useState({
		companyId: '',
		insurancePlanId: '',
		code: '',
		acquisitionPercentage: '',
		productType: '',
		productCategoryId: '',
		vehicleType: [],
		isActive: false,
		isDeleted: false,
	});

	const [pricinginputFields, setpricinginputFields] = useState([
		{
			vehicleCategory: '',
			minValue: '',
			maxValue: '',
			region1Value: '',
			region2Value: '',
			region3Value: '',
		},
	]);

	const [riderinputFields, setriderinputFields] = useState([
		{
			displayName: '',
			code: '',
			region1Value: '',
			region2Value: '',
			region3Value: '',
			displayType: '',
			/* defaultSelected: '',
			limit: '',
			default: '', */
		},
	]);
	const [thirdPartiesinputFields, setthirdPartiesinputFields] = useState([
		{
			vehicleCategory: '',
			minValue: '',
			maxValue: '',
			percentage: '',
		},
	]);

	const loadInitData = () => {
		ProductService.getInitData()
			.then((response) => {
				// setcompanyNameLists(response?.data?.data);
				const res = response?.data?.data;
				if (res) {
					Cookies.set('CSRF-TOKEN', response.headers['xsrf-token']);
					setcompanyNameLists(res?.companiesList);
					setvehicleTypeList(res?.vehicleType);
					setvehicleCategoryList(res?.vehicleCategory);
					setproductTypeList(res.productType);
					setproductCategoryList(res.productCategoryList);
				}
				console.log(res);
			})
			.catch((err) => {
				if (err?.response?.data) {
					enqueueSnackbar(err.response.data.message, { variant: 'error' });
				} else {
					enqueueSnackbar(err.error_message, { variant: 'error' });
				}
			});
	};

	const loadInsurancePlan = async (selectedCompanyNameID) => {
		const res = await ProductService.getInsurancePlanList(selectedCompanyNameID)
			.then((response) => {
				setinsurancePlanNameLists(response?.data?.data);
			})
			.catch((err) => {
				setinsurancePlanNameLists([]);
				if (err?.response?.data) {
					enqueueSnackbar(err.response.data.message, { variant: 'error' });
				} else {
					enqueueSnackbar(err.error_message, { variant: 'error' });
				}
			});
	};

	useEffect(() => {
		loadInitData();
	}, []);

	useEffect(() => {
		if (mode === 'edit') {
			let company = companyNameLists.filter((element) => element.id === data?.companyId)[0];
			let insurance = insurancePlanNameLists.filter((element) => element.id === data?.insurancePlanId)[0];
			if (company) {
				setSelectedProductCode(company?.code);
				setCompanyName(company?.companyName);
				setinsurancePlanName(insurance?.planName);
			}
		}
	}, [insurancePlanNameLists]);

	useEffect(() => {
		if (mode === 'edit') {
			loadInsurancePlan(data?.companyId);

			setProductField({
				companyId: data?.companyId,
				insurancePlanId: data?.insurancePlanId,
				code: data?.code,
				acquisitionPercentage: data?.acquisitionPercentage,
				productType: data?.productType,
				vehicleType: data?.vehicleType,
				isActive: data?.isActive,
				isDeleted: data?.isDeleted,
				productCategoryId: data?.productCategoryId || '',
			});

			if (data?.price) {
				setpricinginputFields(data.price);
			}

			if (data?.riders) {
				setriderinputFields(data.riders);
			}

			if (data?.thirdParties) {
				setthirdPartiesinputFields(data.thirdParties);
			}
		}
	}, [data, companyNameLists]);

	const displayTypeList = [
		{ displayName: 'Single Tier', code: 'singleTier' },
		{ displayName: 'Double Tier', code: 'doubleTier' },
	];

	const handleCompanyChanges = (event) => {
		let selectedCompanyID = event.target.value;
		let company = companyNameLists.filter((element) => element.id === selectedCompanyID)[0];
		setSelectedProductCode(company.code);
		setCompanyName(company.companyName);
		handleFieldValue(event);
		loadInsurancePlan(selectedCompanyID);
	};

	const handleInsuranceChanges = (event) => {
		let selectedId = event.target.value;
		let insurance = insurancePlanNameLists.filter((element) => element.id === selectedId)[0];
		setinsurancePlanName(insurance.planName);
		handleFieldValue(event);
	};

	const handlePriceAddFields = () => {
		let priceData = {
			vehicleCategory: '',
			minValue: '',
			maxValue: '',
			region1Value: '',
			region2Value: '',
			region3Value: '',
		};
		setpricinginputFields([...pricinginputFields, priceData]);
		setShowErrorField(false);
		setErrorMessages([]);
	};

	const handlePriceDeleteFields = (row = -1) => {
		let arr = [...pricinginputFields];
		if (row != -1) {
			arr.splice(row, 1);
			setpricinginputFields(arr);
		} else {
			setpricinginputFields(arr.slice(0, -1));
		}
	};

	const handleRidersAddFields = () => {
		let ridersData = {
			displayName: '',
			code: '',
			region1Value: '',
			region2Value: '',
			region3Value: '',
			displayType: '',
			/* defaultSelected: '',
			limit: '',
			default: '', */
		};
		setriderinputFields([...riderinputFields, ridersData]);
		setShowErrorField(false);
		setErrorMessages([]);
	};

	const handleRidersDeleteFields = (row = -1) => {
		let arr = [...riderinputFields];
		if (row != -1) {
			arr.splice(row, 1);
			setriderinputFields(arr);
		} else {
			setriderinputFields(arr.slice(0, -1));
		}
	};

	const handlethirdPartiesAddFields = () => {
		let thirdPartiesData = {
			thirdPartiesvehicleCategory: '',
			thirdPartiesminValue: '',
			thirdPartiesmaxValue: '',
			thirdPartiespercentage: '',
		};
		setthirdPartiesinputFields([...thirdPartiesinputFields, thirdPartiesData]);
		setShowErrorField(false);
		setErrorMessages([]);
	};

	const handlethirdPartiesDeleteFields = (row = -1) => {
		let arr = [...thirdPartiesinputFields];
		if (row != -1) {
			arr.splice(row, 1);
			setthirdPartiesinputFields(arr);
		} else {
			setthirdPartiesinputFields(arr.slice(0, -1));
		}
	};

	const handleFieldValue = (event) => {
		setProductField((prevState) => {
			return {
				...prevState,
				[event.target.name]: event.target.value,
			};
		});
	};

	const handleChangeVehicleType = (event) => {
		const {
			target: { value },
		} = event;

		setProductField((prevState) => {
			console.log(value);
			return {
				...prevState,
				vehicleType: typeof value === 'string' ? value.split(',') : value,
			};
		});
	};

	const handlePriceValue = (event, row) => {
		setpricinginputFields(
			[...pricinginputFields].map((object, index) => {
				if (index == row) {
					return {
						...object,
						[event.target.name]: event.target.value,
					};
				} else {
					return object;
				}
			})
		);
	};

	const handleRiderValue = (event, row) => {
		setriderinputFields(
			[...riderinputFields].map((object, index) => {
				if (index == row) {
					return {
						...object,
						[event.target.name]: event.target.value,
					};
				} else {
					return object;
				}
			})
		);
	};

	const handleThirdPartiesValue = (event, row) => {
		setthirdPartiesinputFields(
			[...thirdPartiesinputFields].map((object, index) => {
				if (index == row) {
					return {
						...object,
						[event.target.name]: event.target.value,
					};
				} else {
					return object;
				}
			})
		);
	};

	const validateArrayData = (arr, title) => {
		let msg = [];
		arr.map((data, index) => {
			const keys = Object.keys(data);
			keys.map((item) => {
				if (data[item] === '') {
					msg.push(`${item} on ${title} form row ${index + 1} is required`);
				}
			});
		});
		return msg;
	};

	const validateForm = (data) => {
		let msg = [];
		setErrorMessages([]);
		const keys = Object.keys(data);
		keys.map((item) => {
			if (data[item] === '') {
				msg.push(`${item} is required`);
			}
		});

		const priceData = data.price;
		const priceError = validateArrayData(priceData, 'Price');

		const ridersData = data.riders;
		const ridersError = validateArrayData(ridersData, 'Riders');

		const thirdPartiesData = data.thirdParties;
		const thirdPartiesError = validateArrayData(thirdPartiesData, 'Third Parties');

		msg = msg.concat(priceError, ridersError, thirdPartiesError);
		setErrorMessages(msg);
		/* errorMessages.map((msg) => {
			enqueueSnackbar(msg, {
				variant: 'error',
			});
		}); */

		return msg.length === 0;
	};

	const handleSubmit = (event) => {
		setLoading(true);
		setShowErrorField(true);
		let finalData = {
			displayName: companyName + '-' + insurancePlanName,
			code: selectedProductCode.toUpperCase(),
			productCategoryId: productField.productCategoryId,
			companyId: productField.companyId,
			insurancePlanId: productField.insurancePlanId,
			// insuranceSubPlanId: '',
			price: pricinginputFields,
			riders: riderinputFields,
			thirdParties: thirdPartiesinputFields,
			rating: 4.5,
			actionCategory: 'Cheapest',
			couponCode: '605 Fuse Points',
			acquisitionPercentage: productField.acquisitionPercentage,
			productType: productField.productType,
			vehicleType: productField.vehicleType,
			isActive: productField.isActive,
			isDeleted: productField.isDeleted,
			pointsPercentage: '15',
		};

		const isValid = validateForm(finalData);
		if (!isValid) {
			//window.scrollTo(0, 0)
			setLoading(false);

			return;
		}

		const xsrfToken = Cookies.get('CSRF-TOKEN');

		if (mode == 'add') {
			ProductService.createProduct(finalData, xsrfToken)
				.then((response) => {
					setLoading(false);
					enqueueSnackbar('Changes made have been successfully saved', {
						variant: 'success',
					});
					props.history.push({
						pathname: '/products',
					});
				})
				.catch((err) => {
					setLoading(false);

					if (err?.response?.data.statusCode === 400) {
						if (err?.response?.data?.data) {
							for (let i = 0; i < err?.response?.data?.data.length; i++) {
								enqueueSnackbar(err?.response?.data[i].message || 'Unable to create product', {
									variant: 'error',
								});
							}
						} else {
							enqueueSnackbar(err?.response?.data.message || 'Unable to create product', { variant: 'error' });
						}
					} else {
						console.log(err.error_message);
						enqueueSnackbar(err.error_message || 'Unable to create product', { variant: 'error' });
					}
				});
		} else {
			ProductService.updateProduct(data.id, finalData, xsrfToken)
				.then((response) => {
					setLoading(false);
					enqueueSnackbar('Changes made have been successfully saved', {
						variant: 'success',
					});
					props.history.push({
						pathname: '/products',
					});
				})
				.catch((err) => {
					setLoading(false);
					if (err?.response?.data.statusCode === 400) {
						console.log(err.response.data.data.length);
						for (let i = 0; i < err.response.data.data.length; i++) {
							enqueueSnackbar(err.response.data.data[i].message, {
								variant: 'error',
							});
						}
					} else {
						console.log(err.error_message);
						enqueueSnackbar(err.error_message || 'Unable to update product', { variant: 'error' });
					}
				});
		}
	};

	return (
		<Grid>
			{errorMessages.length > 0 && <Error messages={errorMessages} />}
			<ProductWrapper>
				<SectionContent>
					<FormControlCol3>
						<InputLabel>
							Company Name <span style={{ color: 'red' }}>*</span>
						</InputLabel>
						<Select
							id="company_name"
							defaultValue="Select Company Name"
							name="companyId"
							onChange={handleCompanyChanges}
							size="small"
							value={productField?.companyId && productField?.companyId}
							error={showErrorField && (productField?.companyId === '' || productField?.companyId === null)}
						>
							{companyNameLists.map((comp, index) => (
								<MenuItem key={index} value={comp.id}>
									{comp.companyName}
								</MenuItem>
							))}
						</Select>
						{showErrorField && (productField.companyId === '' || productField?.companyId === null) ? (
							<FormHelperText error>Please Select!</FormHelperText>
						) : (
							''
						)}
					</FormControlCol3>

					<FormControlCol3>
						<InputLabel>
							Insurance Plan Name <span style={{ color: 'red' }}>*</span>
						</InputLabel>
						<Select
							id="insurance_plan_name"
							defaultValue="Select Insurance Plan Name"
							name="insurancePlanId"
							onChange={handleInsuranceChanges}
							size="small"
							value={productField.insurancePlanId && productField.insurancePlanId}
							error={showErrorField && (productField.insurancePlanId === '' || productField.insurancePlanId === null)}
						>
							{insurancePlanNameLists.map((insurance, index) => (
								<MenuItem key={index} value={insurance.id}>
									{insurance.planName}
								</MenuItem>
							))}
						</Select>
						{showErrorField && (productField.insurancePlanId === '' || productField.insurancePlanId === null) ? (
							<FormHelperText error>Please Select!</FormHelperText>
						) : (
							''
						)}
					</FormControlCol3>

					<FormControlCol3>
						<InputLabel>
							Product Code<span style={{ color: 'red' }}>*</span>
						</InputLabel>
						<OutlinedInput
							id="product_code"
							name="product_code"
							value={selectedProductCode && selectedProductCode}
							onChange={handleFieldValue}
							size="small"
							error={showErrorField && selectedProductCode === ''}
						/>
						{showErrorField && selectedProductCode === '' ? <FormHelperText error>Empty field!</FormHelperText> : ''}
					</FormControlCol3>
				</SectionContent>

				<SectionContent>
					<FormControlCol3>
						<InputLabel>
							Product Name <span style={{ color: 'red' }}>*</span>
						</InputLabel>
						<MuiTextField
							id="product_name"
							name="product_name"
							value={companyName && insurancePlanName ? companyName + '-' + insurancePlanName : null}
							size="small"
							error={showErrorField && companyName + '-' + insurancePlanName === '-'}
							helperText={showErrorField && companyName + '-' + insurancePlanName === '-' ? 'Empty field!' : ''}
						/>
					</FormControlCol3>

					<FormControlCol3>
						<InputLabel>
							Acquisition Cost<span style={{ color: 'red' }}>*</span> (%)
						</InputLabel>
						<MuiTextField
							margin="normal"
							name="acquisitionPercentage"
							onChange={(e) => {
								if (e.target.value > 100) {
									return;
								}
								handleFieldValue(e);
							}}
							type="number"
							variant="outlined"
							size="small"
							inputProps={{
								max: 100,
								min: 0,
							}}
							value={productField.acquisitionPercentage && productField.acquisitionPercentage}
							error={showErrorField && productField.acquisitionPercentage === ''}
							helperText={showErrorField && productField.acquisitionPercentage === '' ? 'Empty field!' : ''}
						/>
					</FormControlCol3>

					<FormControlCol3>
						<InputLabel>
							Product Type<span style={{ color: 'red' }}>*</span>
						</InputLabel>
						<Select
							id="product_type"
							defaultValue="Product Type"
							name="productType"
							onChange={handleFieldValue}
							size="small"
							value={productField.productType && productField.productType}
							error={showErrorField && productField.productType === ''}
						>
							{productTypeList.map((productTypeList, index) => (
								<MenuItem key={index} value={productTypeList.code}>
									{productTypeList.displayName}
								</MenuItem>
							))}
						</Select>
						{showErrorField && productField.productType === '' ? (
							<FormHelperText error>Please Select!</FormHelperText>
						) : (
							''
						)}
					</FormControlCol3>

					<FormControlCol3>
						<InputLabel>
							Product Category<span style={{ color: 'red' }}>*</span>
						</InputLabel>
						<Select
							id="productCategoryId"
							defaultValue="Product Category"
							name="productCategoryId"
							onChange={handleFieldValue}
							size="small"
							value={productField.productCategoryId && productField.productCategoryId}
							error={showErrorField && productField.productCategoryId === ''}
						>
							{productcategoryList.map((productCategory, index) => (
								<MenuItem key={index} value={productCategory.id}>
									{productCategory.displayName}
								</MenuItem>
							))}
						</Select>
						{showErrorField && productField.productCategoryId === '' ? (
							<FormHelperText error>Please Select!</FormHelperText>
						) : (
							''
						)}
					</FormControlCol3>

					<FormControlCol3>
						<InputLabel>
							Vehicle Type<span style={{ color: 'red' }}>*</span>
						</InputLabel>
						<Select
							placeholder="Vehicle Type"
							name="vehicleType"
							onChange={handleChangeVehicleType}
							multiple
							size="small"
							input={<OutlinedInput label="Name" />}
							value={productField.vehicleType && productField.vehicleType}
							error={showErrorField && productField.vehicleType === ''}
							renderValue={(selected) => selected.join(', ')}
						>
							{vehicleTypeList.map((item) => (
								<MenuItem key={item.code} value={item.code}>
									<Checkbox checked={productField.vehicleType.indexOf(item.code) > -1} /> {item.displayName}
								</MenuItem>
							))}
						</Select>
					</FormControlCol3>

					<FormControlCol3>
						<InputLabel>
							Active<span style={{ color: 'red' }}>*</span>
						</InputLabel>
						<Select
							placeholder="Active"
							name="isActive"
							onChange={handleFieldValue}
							size="small"
							value={productField.isActive || false}
							error={showErrorField && productField.isActive === ''}
						>
							<MenuItem key={0} value={false}>
								False
							</MenuItem>
							<MenuItem key={1} value={true}>
								True
							</MenuItem>
						</Select>
						{showErrorField && productField.isActive === '' ? (
							<FormHelperText error>Please Select!</FormHelperText>
						) : (
							''
						)}
					</FormControlCol3>

					{/* <FormControlCol3>
						<InputLabel>Deleted</InputLabel>
						<Select
							placeholder="Deleted"
							name="isDeleted"
							onChange={handleFieldValue}
							size="small"
							value={productField.isDeleted || false}
							error={showErrorField && productField.isDeleted === ''}
						>
							<MenuItem key={0} value={false}>
								False
							</MenuItem>
							<MenuItem key={1} value={true}>
								True
							</MenuItem>
						</Select>
						{showErrorField && productField.isDeleted === '' ? (
							<FormHelperText error>Please Select!</FormHelperText>
						) : (
							''
						)}
					</FormControlCol3> */}
				</SectionContent>

				{/* Pricing */}
				<SectionContent>
					<FormControl>
						<Title>Pricing</Title>
					</FormControl>
				</SectionContent>

				<SectionContent>
					{pricinginputFields.map((pricinginputEvents, row) => (
						<SectionContentAddRow elevation={4} key={row}>
							<SectionContent>
								<FormControlPricing>
									{
										<InputLabel>
											Vehicle Category<span style={{ color: 'red' }}>*</span>
										</InputLabel>
									}
									<Select
										defaultValue="Vehicle Category"
										placeholder="Vehicle Category"
										name="vehicleCategory"
										onChange={(event) => handlePriceValue(event, row)}
										size="small"
										value={pricinginputFields[row].vehicleCategory && pricinginputFields[row].vehicleCategory}
										error={showErrorField && pricinginputFields[row].vehicleCategory === ''}
									>
										{vehicleCategoryList.map((vehicleCategoryList, index) => (
											<MenuItem key={index} value={vehicleCategoryList}>
												{vehicleCategoryList}
											</MenuItem>
										))}
									</Select>
									{showErrorField && pricinginputFields[row].vehicleCategory === '' ? (
										<FormHelperText error>Please Select!</FormHelperText>
									) : (
										''
									)}
								</FormControlPricing>

								<FormControlPricing>
									<InputLabel>
										Min Value<span style={{ color: 'red' }}>*</span>
									</InputLabel>
									<MuiTextField
										margin="normal"
										name="minValue"
										value={pricinginputFields[row].minValue && pricinginputFields[row].minValue}
										placeholder="Min Value"
										onChange={(event) => handlePriceValue(event, row)}
										type="number"
										variant="outlined"
										size="small"
										error={showErrorField && pricinginputFields[row].minValue === ''}
										helperText={showErrorField && pricinginputFields[row].minValue === '' ? 'Empty field!' : ''}
									/>
								</FormControlPricing>

								<FormControlPricing>
									<InputLabel>
										Max Value<span style={{ color: 'red' }}>*</span>
									</InputLabel>
									<MuiTextField
										margin="normal"
										name="maxValue"
										value={pricinginputFields[row].maxValue && pricinginputFields[row].maxValue}
										placeholder="Max Value"
										onChange={(event) => handlePriceValue(event, row)}
										type="number"
										variant="outlined"
										size="small"
										error={showErrorField && pricinginputFields[row].maxValue === ''}
										helperText={showErrorField && pricinginputFields[row].maxValue === '' ? 'Empty field!' : ''}
									/>
								</FormControlPricing>

								<FormControlPricing>
									<InputLabel>
										Region 1 Value <span style={{ color: 'red' }}>*</span>
									</InputLabel>
									<MuiTextField
										margin="normal"
										name="region1Value"
										value={pricinginputFields[row].region1Value && pricinginputFields[row].region1Value}
										placeholder="Region 1 Value"
										onChange={(event) => handlePriceValue(event, row)}
										type="number"
										inputProps={{
											maxLength: 4,
											step: '0.05',
										}}
										variant="outlined"
										size="small"
										error={showErrorField && pricinginputFields[row].region1Value === ''}
										helperText={showErrorField && pricinginputFields[row].region1Value === '' ? 'Empty field!' : ''}
									/>
								</FormControlPricing>

								<FormControlPricing>
									<InputLabel>
										Region 2 Value <span style={{ color: 'red' }}>*</span>
									</InputLabel>
									<MuiTextField
										margin="normal"
										name="region2Value"
										value={pricinginputFields[row].region2Value && pricinginputFields[row].region2Value}
										placeholder="Region 2 Value"
										onChange={(event) => handlePriceValue(event, row)}
										type="number"
										inputProps={{
											maxLength: 4,
											step: '0.05',
										}}
										variant="outlined"
										size="small"
										error={showErrorField && pricinginputFields[row].region2Value === ''}
										helperText={showErrorField && pricinginputFields[row].region2Value === '' ? 'Empty field!' : ''}
									/>
								</FormControlPricing>

								<FormControlPricing>
									<InputLabel>
										Region 3 Value <span style={{ color: 'red' }}>*</span>
									</InputLabel>
									<MuiTextField
										margin="normal"
										name="region3Value"
										value={pricinginputFields[row].region3Value && pricinginputFields[row].region3Value}
										placeholder="Region 3 Value"
										onChange={(event) => handlePriceValue(event, row)}
										type="number"
										inputProps={{
											maxLength: 4,
											step: '0.05',
										}}
										variant="outlined"
										size="small"
										error={showErrorField && pricinginputFields[row].region3Value === ''}
										helperText={showErrorField && pricinginputFields[row].region3Value === '' ? 'Empty field!' : ''}
									/>
								</FormControlPricing>

								{row !== 0 ? (
									<FormControlPricing>
										<InputLabel>&nbsp;</InputLabel>
										<AddButton
											variant="contained"
											color="error"
											size="small"
											onClick={(e) => handlePriceDeleteFields(row)}
										>
											Remove This Row
										</AddButton>
									</FormControlPricing>
								) : (
									''
								)}
							</SectionContent>
						</SectionContentAddRow>
					))}
					<Grid container direction="row" spacing={1} justifyContent="start" alignItems="center" p={3}>
						<Grid item={true} xs={2}>
							<AddButton variant="outlined" size="small" onClick={() => handlePriceAddFields()}>
								Add New Price Row
							</AddButton>
						</Grid>
						{/* <Grid item={true} xs={2}>
							<AddButton
								disabled={pricinginputFields.length <= 1}
								variant="outlined"
								size="small"
								onClick={() => handlePriceDeleteFields()}
							>
								Delete New Price Row
							</AddButton>
						</Grid> */}
					</Grid>
				</SectionContent>

				{/* Riders */}
				<SectionContent>
					<FormControl>
						<Title>Riders</Title>
					</FormControl>
				</SectionContent>

				<SectionContent>
					{riderinputFields.map((riderinputEvents, row) => (
						<SectionContentAddRow elevation={4} key={row}>
							<FormControlRiders>
								<InputLabel>
									Rider Name<span style={{ color: 'red' }}>*</span>
								</InputLabel>
								<MuiTextField
									margin="normal"
									name="displayName"
									value={riderinputFields[row].displayName && riderinputFields[row].displayName}
									placeholder="Rider Name"
									onChange={(event) => {
										handleRiderValue(event, row);
									}}
									type="text"
									variant="outlined"
									size="small"
									error={showErrorField && riderinputFields[row].displayName === ''}
									helperText={showErrorField && riderinputFields[row].displayName === '' ? 'Empty field!' : ''}
								/>
							</FormControlRiders>

							<FormControlRiders>
								<InputLabel>
									Rider Code<span style={{ color: 'red' }}>*</span>
								</InputLabel>
								<MuiTextField
									margin="normal"
									name="code"
									value={riderinputFields[row].code && riderinputFields[row].code}
									placeholder="Rider Code"
									onChange={(event) => {
										handleRiderValue(event, row);
									}}
									type="text"
									variant="outlined"
									size="small"
									error={showErrorField && riderinputFields[row].code === ''}
									helperText={showErrorField && riderinputFields[row].code === '' ? 'Empty field!' : ''}
								/>
							</FormControlRiders>

							<FormControlRiders>
								<InputLabel>
									Display Type<span style={{ color: 'red' }}>*</span>
								</InputLabel>
								<Select
									defaultValue="Display Type"
									placeholder="Display Type"
									name="displayType"
									value={riderinputFields[row].displayType && riderinputFields[row].displayType}
									onChange={(event) => {
										handleRiderValue(event, row);
									}}
									size="small"
									error={showErrorField && riderinputFields[row].displayType === ''}
								>
									{displayTypeList.map((displayTypeList, index) => (
										<MenuItem key={index} value={displayTypeList.code}>
											{displayTypeList.displayName}
										</MenuItem>
									))}
								</Select>
								{showErrorField && riderinputFields[row].displayType === '' ? (
									<FormHelperText error>Please Select!</FormHelperText>
								) : (
									''
								)}
							</FormControlRiders>

							<FormControlRiders>
								<InputLabel>
									Region 1 Value<span style={{ color: 'red' }}>*</span>
								</InputLabel>
								<MuiTextField
									margin="normal"
									name="region1Value"
									value={riderinputFields[row].region1Value && riderinputFields[row].region1Value}
									placeholder="Region 1 Value"
									onChange={(event) => {
										handleRiderValue(event, row);
									}}
									type="number"
									inputProps={{
										maxLength: 4,
										step: '0.005',
									}}
									variant="outlined"
									size="small"
									error={showErrorField && riderinputFields[row].region1Value === ''}
									helperText={showErrorField && riderinputFields[row].region1Value === '' ? 'Empty field!' : ''}
								/>
							</FormControlRiders>

							<FormControlRiders>
								<InputLabel>
									Region 2 Value<span style={{ color: 'red' }}>*</span>
								</InputLabel>
								<MuiTextField
									margin="normal"
									name="region2Value"
									value={riderinputFields[row].region2Value && riderinputFields[row].region2Value}
									placeholder="Region 2 Value"
									onChange={(event) => {
										handleRiderValue(event, row);
									}}
									type="number"
									inputProps={{
										maxLength: 4,
										step: '0.1',
									}}
									variant="outlined"
									size="small"
									error={showErrorField && riderinputFields[row].region2Value === ''}
									helperText={showErrorField && riderinputFields[row].region2Value === '' ? 'Empty field!' : ''}
								/>
							</FormControlRiders>

							<FormControlRiders>
								<InputLabel>
									Region 3 Value<span style={{ color: 'red' }}>*</span>
								</InputLabel>
								<MuiTextField
									margin="normal"
									name="region3Value"
									value={riderinputFields[row].region3Value && riderinputFields[row].region3Value}
									placeholder="Region 3 Value"
									onChange={(event) => {
										handleRiderValue(event, row);
									}}
									type="number"
									inputProps={{
										maxLength: 4,
										step: '0.005',
									}}
									variant="outlined"
									size="small"
									error={showErrorField && riderinputFields[row].region3Value === ''}
									helperText={showErrorField && riderinputFields[row].region3Value === '' ? 'Empty field!' : ''}
								/>
							</FormControlRiders>

							{row !== 0 ? (
								<FormControlRiders>
									<InputLabel>&nbsp;</InputLabel>
									<AddButton
										variant="contained"
										color="error"
										size="small"
										onClick={(e) => handleRidersDeleteFields(row)}
									>
										Remove This Row
									</AddButton>
								</FormControlRiders>
							) : (
								''
							)}
						</SectionContentAddRow>
					))}
					<Grid container direction="row" spacing={1} justifyContent="start" alignItems="center" p={3}>
						<Grid item={true} xs={2}>
							<AddButton variant="outlined" size="small" onClick={() => handleRidersAddFields()}>
								Add New Riders Row
							</AddButton>
						</Grid>
						{/* <Grid item={true} xs={2}>
							<AddButton
								disabled={riderinputFields.length <= 1}
								variant="outlined"
								size="small"
								onClick={() => handleRidersDeleteFields()}
							>
								Delete New Riders Row
							</AddButton>
						</Grid> */}
					</Grid>
				</SectionContent>

				{/* Third Parties */}
				<SectionContent>
					<FormControl>
						<Title>Third Parties</Title>
					</FormControl>
				</SectionContent>

				<SectionContent>
					{thirdPartiesinputFields.map((thirdPartiesinputEvents, row) => (
						<SectionContentAddRow elevation={4} key={row}>
							<FormControlThirdParties>
								<InputLabel>
									Vehicle Category<span style={{ color: 'red' }}>*</span>
								</InputLabel>
								<Select
									defaultValue="Vehicle Category"
									placeholder="Vehicle Category"
									name="vehicleCategory"
									onChange={(event) => {
										handleThirdPartiesValue(event, row);
									}}
									size="small"
									value={thirdPartiesinputFields[row].vehicleCategory || ''}
									error={showErrorField && thirdPartiesinputFields[row].vehicleCategory === ''}
								>
									{vehicleCategoryList.map((vehicleCategoryList, index) => (
										<MenuItem key={index} value={vehicleCategoryList}>
											{vehicleCategoryList}
										</MenuItem>
									))}
								</Select>
								{showErrorField && thirdPartiesinputFields[row].vehicleCategory === '' ? (
									<FormHelperText error>Please Select!</FormHelperText>
								) : (
									''
								)}
							</FormControlThirdParties>

							<FormControlThirdParties>
								<InputLabel>
									Min Value<span style={{ color: 'red' }}>*</span>
								</InputLabel>
								<MuiTextField
									margin="normal"
									name="minValue"
									value={thirdPartiesinputFields[row].minValue && thirdPartiesinputFields[row].minValue}
									placeholder="Min Value"
									onChange={(event) => {
										handleThirdPartiesValue(event, row);
									}}
									type="number"
									variant="outlined"
									size="small"
									error={showErrorField && thirdPartiesinputFields[row].minValue === ''}
									helperText={showErrorField && thirdPartiesinputFields[row].minValue === '' ? 'Empty field!' : ''}
								/>
							</FormControlThirdParties>

							<FormControlThirdParties>
								<InputLabel>
									Max Value<span style={{ color: 'red' }}>*</span>
								</InputLabel>
								<MuiTextField
									margin="normal"
									name="maxValue"
									value={thirdPartiesinputFields[row].maxValue && thirdPartiesinputFields[row].maxValue}
									placeholder="Max Value"
									onChange={(event) => {
										handleThirdPartiesValue(event, row);
									}}
									type="number"
									variant="outlined"
									size="small"
									error={showErrorField && thirdPartiesinputFields[row].maxValue === ''}
									helperText={showErrorField && thirdPartiesinputFields[row].maxValue === '' ? 'Empty field!' : ''}
								/>
							</FormControlThirdParties>

							<FormControlThirdParties>
								<InputLabel>
									Percentage<span style={{ color: 'red' }}>*</span> (%)
								</InputLabel>
								<MuiTextField
									margin="normal"
									name="percentage"
									value={thirdPartiesinputFields[row].percentage && thirdPartiesinputFields[row].percentage}
									placeholder="Percentage %"
									onChange={(event) => {
										handleThirdPartiesValue(event, row);
									}}
									type="number"
									inputProps={{
										maxLength: 4,
										step: '0.05',
									}}
									variant="outlined"
									size="small"
									error={showErrorField && thirdPartiesinputFields[row].percentage === ''}
									helperText={showErrorField && thirdPartiesinputFields[row].percentage === '' ? 'Empty field!' : ''}
								/>
							</FormControlThirdParties>

							{row !== 0 ? (
								<FormControlThirdParties>
									<InputLabel>&nbsp;</InputLabel>
									<AddButton
										variant="contained"
										color="error"
										size="small"
										onClick={(e) => handlethirdPartiesDeleteFields(row)}
									>
										Remove This Row
									</AddButton>
								</FormControlThirdParties>
							) : (
								''
							)}
						</SectionContentAddRow>
					))}
					<Grid container direction="row" spacing={1} justifyContent="start" alignItems="center" p={3}>
						<Grid item={true} xs={2}>
							<AddButton variant="outlined" size="small" onClick={() => handlethirdPartiesAddFields()}>
								Add New Third Parties Row
							</AddButton>
						</Grid>
						{/* <Grid item={true} xs={2}>
							<AddButton
								disabled={thirdPartiesinputFields.length <= 1}
								variant="outlined"
								size="small"
								onClick={() => handlethirdPartiesDeleteFields()}
							>
								Delete New Third Parties Row
							</AddButton>
						</Grid> */}
					</Grid>
				</SectionContent>
			</ProductWrapper>

			<Grid container direction="row" justifyContent="start" alignItems="center" p={3}>
				{/* <MuiButton variant='outlined' to='/products'>Back</MuiButton> */}
				<MuiButton variant="contained" onClick={handleSubmit} color="primary" disable={`${loading}`}>
					{loading && <CircularProgress style={{ color: 'white', margin: '2px' }} size={20} />}Submit
				</MuiButton>
				{/* <Button variant='contained' onClick={() => handleSubmit()} color='primary' disable={loading}>Submit</Button> */}
			</Grid>
		</Grid>
	);
};

export default ProductForm;
