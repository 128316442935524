import React from 'react';
import { Dialog, DialogContent, IconButton, Typography, Grid, Box } from '@mui/material';
import { Close } from '@mui/icons-material';
import Moment from 'moment';

import { List, ListWrapper } from './style';

const DialogTitle = (props) => {
	const { children, onClose } = props;
	return (
		<Grid
			container
			direction="row"
			justifyContent="space-between"
			alignItems="center"
			// disableTypography {...other}
			p={3}
			pb={0}
		>
			<Typography variant="h5">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" onClick={onClose}>
					<Close />
				</IconButton>
			) : null}
		</Grid>
	);
};

const ClaimView = (props) => {
	const { onClose, open, data } = props;
	console.log(data);

	const listFunc = (title, value) => {
		return (
			value && (
				<List>
					<b>{title}</b> : {value}
				</List>
			)
		);
	};

	return (
		<Dialog onClose={onClose} aria-labelledby="view-claim" open={open} maxWidth="lg" fullWidth>
			<DialogTitle id="view-claim" onClose={onClose}>
				Detail
			</DialogTitle>
			<DialogContent>
				<Box>
					<Grid container px={2}>
						<Typography variant="h6" sx={{ width: '100%' }}>
							General details
						</Typography>
						<ListWrapper>
							{listFunc('Insured Name', data?.insuredName)}
							{listFunc('Vehicle Registration', data?.vehicleRegistration)}
							{listFunc('Location', data?.location)}
							{listFunc('Loss Cause', data?.lossCause)}
							{listFunc('Loss Date', Moment(data?.lossDate).format('DD MMM Y'))}
							{listFunc('Policy No', data?.policyNo)}
							{listFunc('Drive by', data?.driveBy)}
						</ListWrapper>

						<Typography variant="h6" sx={{ width: '100%' }}>
							Workshop details
						</Typography>
						<ListWrapper>
							{listFunc('Name', data?.workshop?.displayName)}
							{listFunc('Type', data?.workshop?.workshopType)}
							{listFunc('Address', data?.workshop?.address)}
						</ListWrapper>

						<Typography variant="h6" sx={{ width: '100%' }}>
							Claim Status
						</Typography>
						<ListWrapper>
							{listFunc('Status', data?.progress[0]?.title)}
							{listFunc('Status Date', Moment(data?.progress[0]?.statusDate).format('DD MMM Y'))}
						</ListWrapper>

						<Typography variant="h6" sx={{ width: '100%' }}>
							Documents
						</Typography>
						<ListWrapper>
							{data?.fileUrls.map((url, index) => (
								<List>
									<b>Document {index + 1}</b> :{' '}
									<a target="_blank" href={url}>
										Download
									</a>
								</List>
							))}
						</ListWrapper>
					</Grid>
				</Box>
			</DialogContent>
		</Dialog>
	);
};

export default ClaimView;
