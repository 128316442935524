import React from 'react';

import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme';
import { SnackbarProvider } from 'notistack';
import { CssBaseline } from '@mui/material';

import BackOfficeRouter from './router';
import { useEffect } from 'react';

const App = () => {
	/* useEffect(() => {
		caches.keys().then((names) => {
			names.forEach((name) => {
				caches.delete(name);
			});
		});
	}, []); */

	return (
		<BrowserRouter>
			<SnackbarProvider maxSnack={6} autoHideDuration={4000}>
				<ThemeProvider theme={theme}>
					<CssBaseline />
					<BackOfficeRouter />
				</ThemeProvider>
			</SnackbarProvider>
		</BrowserRouter>
	);
};

export default App;
