import { create } from '@mui/material/styles/createTransitions';
import axios from 'axios';
import authHeader from './auth-header';

const API_URL = `${process.env.REACT_APP_API_URL}of-payment/`;
const PAYMENT_URL = `${process.env.REACT_APP_PAYMENT_API_URL}`;

const getPaymentList = (page = 0, limit = 5, paymentDate, paymentStatus, company) => {
	console.log(`company : ${company}`);
	let url = `${API_URL}paymentDetails?page=${page}&limit=${limit}`;
	const query = createQuery({
		paymentDate: paymentDate,
		paymentStatus: paymentStatus === 'SELECT ALL' ? null : paymentStatus,
		company: company === 'SELECT ALL' ? null : company,
	});

	url += query;
	return axios.get(url, { headers: authHeader() });
};

const approvePayment = (quotationId, userId) => {
	let url = `${PAYMENT_URL}/payment-link/approve/${quotationId}?userId=${userId}`;
	return axios.get(url, { headers: authHeader() });
};

const declinePayment = (quotationId, userId, declineReason) => {
	const data = {
		userId,
		declineReason,
	};
	let url = `${PAYMENT_URL}/payment-link/decline/${quotationId}`;
	return axios.post(url, data, { headers: authHeader() });
};

const createQuery = (requestList) => {
	let result = '';
	for (const key in requestList) {
		if (requestList[key] != null) {
			result += `&${key}=${requestList[key]}`;
		}
	}
	return result;
};

const getStatus = (quotationId) => {
	let url = `${PAYMENT_URL}/payment-link/status/${quotationId}`;
	return axios.get(url, { headers: authHeader() });
};

const byPassPayment = (quotationId) => {
	let url = `${PAYMENT_URL}/payment-link/bypass-payment/${quotationId}`;
	return axios.get(url, { headers: authHeader() });
};

export default {
	getPaymentList,
	approvePayment,
	declinePayment,
	getStatus,
	byPassPayment,
};
