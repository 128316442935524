import React, { useEffect } from 'react'
import {
  Grid,
  Typography
} from '@mui/material'
import PostLogin from '../../../layout/post-login'
import InsuranceForm from './add'

const CompanyAdd = (props) => {
  return (
    <PostLogin>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <Grid item>
          <Typography variant='h5' gutterBottom component='h5'>Insurance {'Add'}</Typography>
        </Grid>
      </Grid>

      <InsuranceForm
        mode={'add'}
        data={{}}
        {...props}
      />
    </PostLogin>
  )
}

export default CompanyAdd
