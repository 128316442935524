import { styled } from '@mui/system'
import { Box, Paper } from '@mui/material'

export const MuiSelectWrapper = styled(Box)(`
  display: flex;
  flex-direction: column;
`)

export const ProductWrapper = styled(Box)(`
  margin-top:15px;
  padding-top:15px;
`)

export const Title = styled('h2')(`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.48px;
  padding-bottom:10px;
`)

export const FormControl = styled(Box)(`
  display: flex;
  flex-direction: column;
  padding:0 20px;
  @media (max-width: 1000px) {
    margin: .5rem 0;
    width: auto;
  }
  @media (max-width: 600px) {
    padding: 0 10px;
  }
`)

export const FormButtonControl = styled(Box)(`
  display: flex;
  flex-direction: row;

  padding:0 20px 15px;

  & button {
    margin: 0 5px;
  }

  & button svg {
    margin-left:4px;
  }

  @media (max-width: 1000px) {
    margin: .5rem 0;
    width: auto;
  }
  @media (max-width: 600px) {
    padding: 0 10px;
    & > div > a {
      width: calc(100% - 100px);
    }
  }
`)

export const FormLabel = styled('label')(`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #262928;
  width:100%;
`)

export const FormField = styled(Box)(`
  width: 100%;
  & > div {
    min-width: 100%;
  }
`)

export const SectionTitle = styled('h2')(`
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  color: #360D20;
  border-bottom: #360D20 solid 2px;
  width: fit-content;
  @media (max-width: 600px){
    font-size: 15px;
  }
`)

export const SectionContent = styled(Box)(`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 600px){
    flex-direction: column;
    width: 100%; 
  }
`)

export const FormControlCol1 = styled(Box)(`
  display:flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20px 30px;
  @media (max-width: 1000px){
    width: 100%;
  }
  @media (max-width: 600px){
    padding: 0 10px 20px;
  }
`)

export const FormControlCol2 = styled(Box)(`
  display:flex;
  flex-direction:column;
  width: 50%;
  padding:0 20px 30px;
  @media (max-width: 1000px){
    width: 100%;
  }
  @media (max-width: 600px){
    padding: 0 10px 20px;
  }
`)

export const FormControlCol3 = styled(Box)(`
  display:flex;
  flex-direction:column;
  width: 33.33%;
  padding:0 20px 30px;
  @media (max-width: 1000px){
    width: 100%;
  }
  @media (max-width: 600px){
    padding: 0 10px 20px;
  }
`)

export const FormControlCol5 = styled(Box)(`
  display:flex;
  flex-direction:column;
  width: 50%;
  padding:0 20px 30px;
  @media (max-width: 1000px){
    width: 100%;
  }
  @media (max-width: 600px){
    padding: 0 10px 20px;
  }
`)

export const SectionContentAddRow = styled(Paper)(`
  margin:0 auto 10px;
  padding-top:15px;
  width: calc(100% - 40px);
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 600px){
    flex-direction: column;
    width: 100%; 
  }
`)

export const FormControlPricing = styled(Box)(`
  display:flex;
  flex-direction:column;
  width:16.6%;
  padding:0 10px 10px;
  @media (max-width: 1000px){
    width: 100%;
  }
  @media (max-width: 600px){
    padding: 0 10px 20px;
  }
`)

export const FormControlRiders = styled(Box)(`
  display:flex;
  flex-direction:column;
  width:25%;
  padding:0 10px 10px;
  @media (max-width: 1000px){
    width: 100%;
  }
  @media (max-width: 600px){
    padding: 0 10px 20px;
  }
`)

export const FormControlInput = styled(Box)(`
  width: 66.33%;
  display:flex;
  @media (max-width: 1000px){
    flex-direction: column;
    width: 100%;
  }
`)

export const SubmitWrapper = styled(Box)(`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  padding: 0 20px;
  @media (max-width: 1000px){
    padding: 0 10px;
  }
  & > button {
    @media (max-width: 600px){
      min-width: 120px;
    }
  }
`)
