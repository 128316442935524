import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';
import React, { useEffect, useState , isEditable} from 'react'

const VehicleTable=(props)=>{
    const {vehicleData, header}=props;
    const [totalData, setTotalData] = useState(null);
    const [perPageLimit, setPerPageLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [showTableData, setShowTableData] = useState([]);
    const [canEdit, setCanEdit] = useState(false);
    const handlePageChange = (event, newPage) => {
        console.log(newPage)
        setPage(newPage +1)
        setShowTableData(totalData.slice((page - 1) * perPageLimit, page * perPageLimit))
    }
    const handlePerPageChange = (event) => {
        setPerPageLimit(+event.target.value)
        setPage(1)
        setShowTableData(totalData.slice((page - 1) * perPageLimit, page * perPageLimit))
    }
    const handleStatusChanged = (event) => {
      
        if(event.target.value.length){
            const data = vehicleData.filter((item, index) =>{ 
                if(index == 0){
                    console.log(item[0])
                }
                return (item[0] && item[0].match(new RegExp(event.target.value, 'i'))) || (item[2] && item[2].match(new RegExp(event.target.value, 'i')))})
            console.log(data)
     setTotalData(data)
    
       setShowTableData(data.slice((page - 1) * perPageLimit, page * perPageLimit))
        }else{
            setTotalData(vehicleData)
            setShowTableData(vehicleData.slice((page - 1) * perPageLimit, page * perPageLimit))
        }
       
    
    }

    useEffect(() => {
        if(!vehicleData.length){
            setPage(0)
        }
        setTotalData(vehicleData)
        setShowTableData(vehicleData.slice((page - 1) * perPageLimit, page * perPageLimit))
        
        if(isEditable){
            setCanEdit(isEditable || false)
        }
    },[vehicleData])
    return (
        <>
        <Paper sx={{ position: 'relative', overflow: 'auto' , overflowY:'auto'}}>
            <Grid container justifyContent="space-between" alignItems="center" sx={{padding:2}}>
                <Typography variant="h6">Search Brand or Model</Typography>
                <TextField
							autoFocus
							margin="dense"
							id="status"
							fullWidth
							defaultValue={''}
							variant="standard"
							onChange={handleStatusChanged}
						></TextField>
            </Grid>
        <TableContainer>
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow>
                                {header && header.map((item, index) => (
                                    <TableCell key={`${index}_header`} align="left">{item}</TableCell>
                                ))}
							</TableRow>
						</TableHead>
						<TableBody>
							{(!showTableData || showTableData.length == 0) && (
								<TableRow hover role="checkbox">
									<TableCell align="center" colSpan={header.length}> No data found</TableCell>
								</TableRow>
							)}
							{showTableData?.map((row, rowIndex) => (
								<TableRow hover role="checkbox" key={`row_${rowIndex}`}>
									{
                                        canEdit && header.map((item, index) => (
                                            <TableCell key={`${index}_row_${rowIndex}`} align="left">{index==0 ? row[index] : (
                                                 <TextField required id="outlined-required" label="Required" defaultValue={row[index] || '0'} size="small" style={{minWidth: 100}} />)
                                            }</TableCell>
                                        ))
                                    }
                                    {!canEdit && header.map((item, index) => (
                                            <TableCell key={`${index}_row_${rowIndex}`} align="left">{row[index] || 0}</TableCell>
                                        ))}
								</TableRow> 
							))}
						</TableBody>
					</Table>
				</TableContainer>
				{showTableData.length && (<TablePagination
					rowsPerPageOptions={[10, 25, 50, 100]}
					component="div"
					count={totalData?.length || 10}
					rowsPerPage={perPageLimit}
					page={page-1}
					onPageChange={handlePageChange}
                    onRowsPerPageChange={handlePerPageChange}
				/>)}
                </Paper>
			</>
		
    )
}

export default VehicleTable